/* eslint-disable react/forbid-prop-types */

import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';
import concat from 'lodash/concat';
import map from 'lodash/map';
import get from 'lodash/get';
import { SimpleTable } from '@audacious/components/components/Table';
import IconButton from '@audacious/components/components/IconButton';
import Popover from '@audacious/components/components/Popover';
import { faPencil } from '@audacious/icons/regular/faPencil';
import { faExclamationCircle } from '@audacious/icons/regular/faExclamationCircle';
import isEmpty from 'lodash/isEmpty';
import capitalize from 'lodash/capitalize';
import Chip from '@audacious/components/components/Chip';
import patientPropType from '../workflows/main-page/patient-prop-type';
import EditDrawer from '../workflows/main-page/edit-drawer/edit-drawer';

const checkoutTypeColor = {};
checkoutTypeColor.home = 'navy';
checkoutTypeColor.deceased = 'orange';
checkoutTypeColor.other = 'purple';

class ConfirmationTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            patientBeingEdited: null,
        };

        this.handleEditPatient = this.handleEditPatient.bind(this);
        this.handleEditPatientSave = this.handleEditPatientSave.bind(this);
        this.handleCancelEditPatient = this.handleCancelEditPatient.bind(this);

        this.invalidPatientsTableColumns = [
            {
                headerBody: 'Last Name',
                cellValuePath: 'patient.lastName',
                width: '127px',
                bold: true,
            },
            {
                headerBody: 'First Name',
                cellValuePath: 'patient.firstName',
                width: '127px',
            },
            {
                headerBody: '',
                cellValuePath: 'issues',
                width: '64px',
                bold: true,
                renderCell: cellValue => {
                    const issues = map(cellValue, issue => {
                        const { path, messages } = issue;
                        const label = get(issue, 'label', path);
                        const messagesRender = map(messages, message => (
                            <div className="message" key={message}>
                                {message}
                            </div>
                        ));

                        return (
                            <Fragment key={path}>
                                <div className="path">{`${label}:`}</div>
                                {messagesRender}
                            </Fragment>
                        );
                    });
                    if (!isEmpty(issues)) {
                        return (
                            <div className="issue-list">
                                <Popover
                                    anchor={IconButton}
                                    anchorProps={{
                                        icon: faExclamationCircle,
                                        color: 'danger',
                                        size: 'md',
                                    }}
                                >
                                    <div>{issues}</div>
                                </Popover>
                            </div>
                        );
                    }
                    return null;
                },
            },
        ];

        this.validPatientsTableColumns = [
            {
                headerBody: 'Last Name',
                cellValuePath: 'patient.lastName',
                width: '127px',
                bold: true,
            },
            {
                headerBody: 'First Name',
                cellValuePath: 'patient.firstName',
                width: '127px',
            },
        ];

        this.commonColumns = [
            {
                headerBody: 'DOB',
                cellValuePath: 'formatted.dobString',
                width: '116px',
            },
            {
                headerBody: 'Gender',
                cellValuePath: 'formatted.gender',
                width: '100px',
            },
            {
                headerBody: 'Address',
                cellValuePath: [
                    'formatted.address.address1',
                    'formatted.address.address2',
                ],
                width: '194px',
                renderCell: ([line1, line2]) => (
                    <>
                        {!isNil(line1) && line1.length > 0 ? (
                            <div>{line1}</div>
                        ) : null}
                        {!isNil(line2) && line2.length > 0 ? (
                            <div>{line2}</div>
                        ) : null}
                    </>
                ),
            },
            {
                headerBody: 'City',
                cellValuePath: 'formatted.address.city',
                width: '131px',
            },
            {
                headerBody: 'State',
                cellValuePath: 'formatted.address.state',
                width: '70px',
            },
            {
                headerBody: 'Zipcode',
                cellValuePath: 'formatted.address.zipCode',
                width: '150px',
            },
            {
                headerBody: 'Phone',
                cellValuePath: 'formatted.phone',
                width: '196px',
            },
        ];

        this.editColumn = [
            {
                headerBody: 'Edit',
                cellValuePath: '',
                width: '100px',
                renderCell: (cellValue, patient) => (
                    <IconButton
                        icon={faPencil}
                        size="sm"
                        color="primary"
                        onClick={this.handleEditPatient}
                        onClickMeta={patient}
                    />
                ),
            },
        ];

        this.checkoutDestinationColumn = [
            {
                headerBody: 'Destination',
                cellValuePath: 'destination',
                width: '127px',
                renderCell: destination => (
                    <div>
                        <div>
                            <Chip
                                color={checkoutTypeColor[destination.type]}
                                size="sm"
                            >
                                {capitalize(destination.type)}
                            </Chip>
                            {destination.deceasedFormatted}
                        </div>
                        <div>{destination.checkOutLocation}</div>
                        <div>{destination.transferredTo}</div>
                    </div>
                ),
            },
        ];

        this.invalidCheckInsTableColumns = concat(
            this.invalidPatientsTableColumns,
            this.commonColumns,
            this.editColumn,
        );
        this.validCheckInsTableColumns = concat(
            this.validPatientsTableColumns,
            this.commonColumns,
            this.editColumn,
        );
        this.checkoutTableColumns = concat(
            this.validPatientsTableColumns,
            this.commonColumns,
            this.checkoutDestinationColumn,
        );
    }

    handleEditPatient({ meta }) {
        const patientMeta = meta;

        this.setState({ patientBeingEdited: patientMeta });
    }

    handleEditPatientSave() {
        this.setState({ patientBeingEdited: null });
    }

    handleCancelEditPatient() {
        this.setState({
            patientBeingEdited: null,
        });
    }

    renderEditDrawer() {
        const { facilityId, isUpdating } = this.props;
        const { patientBeingEdited } = this.state;
        const open = !isNil(patientBeingEdited);

        if (!open) {
            return null;
        }

        return (
            <EditDrawer
                initialData={patientBeingEdited}
                onCancel={this.handleCancelEditPatient}
                onEditPatientSave={this.handleEditPatientSave}
                facilityId={facilityId}
                isUpdating={isUpdating}
                isCheckedIn={false}
            />
        );
    }

    render() {
        const { items, hasInvalidCheckIns, eventType } = this.props;

        let columns = this.checkoutTableColumns;

        if (eventType === 'check-in') {
            columns = hasInvalidCheckIns
                ? this.invalidCheckInsTableColumns
                : this.validCheckInsTableColumns;
        }
        return (
            <>
                <SimpleTable
                    className="confirmation-table"
                    items={items}
                    columns={columns}
                    pageTable
                    useParentScroll
                />
                {this.renderEditDrawer()}
            </>
        );
    }
}

ConfirmationTable.propTypes = {
    items: PropTypes.arrayOf(patientPropType).isRequired,
    facilityId: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    hasInvalidCheckIns: PropTypes.bool.isRequired,
    eventType: PropTypes.string,
};

ConfirmationTable.defaultProps = {
    eventType: '',
};
export default ConfirmationTable;
