import get from 'lodash/get';

function beforeRequest(actionContext) {
    actionContext.dispatch('RETRIEVE_CHECKED_OUT_PATIENTS_START');
}

function onSuccess(actionContext, { response }) {
    actionContext.dispatch('RETRIEVE_CHECKED_OUT_PATIENTS_FINISH', [
        null,
        response.data,
    ]);
}

function onFailed(actionContext, { response }) {
    actionContext.dispatch('RETRIEVE_CHECKED_OUT_PATIENTS_FINISH', [
        response.error,
    ]);
}

export default config => ({
    serviceName: 'getCheckedOutPatients',
    authenticate: true,
    config: {
        method: 'GET',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkOut.searchQuery'),
        // baseUrl: 'http://localhost:3000'
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
