import PropTypes from 'prop-types';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';
import Dialog from '@audacious/components/components/Dialog';
import { SubHeading, Text } from '@audacious/components/components/Typography';
import Document, {
    DocumentHeader,
    DocumentBody,
    DocumentFooter,
} from '@audacious/components/components/Document';
import Button from '@audacious/components/components/Button';
import { Row, Column } from '@audacious/components/components/Grid';
import FileDrop from '@audacious/components/components/FileDrop';
import LinkButton from '@audacious/components/components/LinkButton';
import { startCheckIn } from '../../../../actions/check-in-actions';
import { processFiles } from '../../../../actions/bulk-upload-actions';
import { buildPatientsCSV, downloadCSV } from '../../../../csv/utilities';
import './bulk-checkin-drawer.scss';

function handleDownloadFile() {
    const csvString = buildPatientsCSV([
        {
            firstName: 'Austin',
            middleName: 'Chris',
            lastName: 'Anderson',
            dob: '1/16/1980',
            gender: 'male',
            address1: '6520 Sint. Center',
            address2: 'Apt 2',
            city: 'Houston',
            state: 'TX',
            zip: '19263',
            countryCode: '',
            phone: '860-718-4530',
            ssn: '888-88-8888',
        },
    ]);

    downloadCSV(csvString, 'check-in');
}

class BulkCheckinDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {};

        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleFileSelect = this.handleFileSelect.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            open,
            fluxibleContext: { executeAction },
        } = this.props;

        const { open: prevOpen } = prevProps;

        if (open && !prevOpen) {
            executeAction(startCheckIn);
        }
    }

    handleOnCancel() {
        const { onCancel } = this.props;
        onCancel();
    }

    handleFileSelect(files) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(processFiles, files);
    }

    render() {
        const { open, bulkData } = this.props;

        let spinner = null;

        if (bulkData.running) {
            spinner = <div className="is-loading-fixed primary" />;
        }

        const descriptionId = 'bulk-upload-drop-description';

        let bulkMessages = null;

        if (!isNil(bulkData.error)) {
            bulkMessages = {
                invalid: [bulkData.error],
            };
        }

        return (
            <Dialog
                open={open}
                size="sm"
                variant="drawer-right"
                className="event-details"
            >
                {spinner}
                <Document size="sm">
                    <DocumentHeader>
                        <SubHeading id="upload-panel-drawer-header" level="4">
                            Bulk Check-In
                        </SubHeading>
                    </DocumentHeader>
                    <DocumentBody>
                        <Row>
                            <Text size="lg" weight="semi-bold">
                                1. Prepare CSV file for a bulk check-in.
                            </Text>
                            <span className="bulk-checkin-instruction-text">
                                Use our sample file to bulk check-in
                                individuals.
                            </span>
                            <Column
                                width={[null, null, '7']}
                                className="bulk-checkin-instruction-text"
                            >
                                <LinkButton
                                    color="primary"
                                    variant="opaque"
                                    onClick={handleDownloadFile}
                                >
                                    Download a sample file
                                </LinkButton>
                            </Column>
                        </Row>
                        <Row>&nbsp;</Row>
                        <Row>
                            <Text size="lg" weight="semi-bold">
                                2. Upload CSV file.
                            </Text>
                        </Row>
                        <Row>
                            <Column width={[null, null, '12']}>
                                <FileDrop
                                    id="bulk-upload"
                                    headerText=""
                                    onFileSelect={this.handleFileSelect}
                                    ariaDescribedBy={[descriptionId]}
                                    messages={bulkMessages}
                                    invalid={!isNil(bulkData.error)}
                                />
                            </Column>
                        </Row>
                    </DocumentBody>
                    <DocumentFooter>
                        <Button
                            color="primary"
                            variant="opaque"
                            id="bulkCheckinDrawerClose"
                            onClick={this.handleOnCancel}
                            focusOnMount
                        >
                            Cancel
                        </Button>
                    </DocumentFooter>
                </Document>
            </Dialog>
        );
    }
}

BulkCheckinDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    bulkData: PropTypes.shape({
        running: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

BulkCheckinDrawer.defaultProps = {};

export default applyFluxibleContext(BulkCheckinDrawer);
