export default {
    emergencyCensus: {
        checkIn: {
            create: '/pulse/v1/census/facilities/{facilityId}/checkin',
            searchQuery: '/pulse/v1/census/facilities/{facilityId}/patients',
            edit: '/pulse/v1/census/facilities/{facilityId}/checkin',
        },
        checkOut: {
            create: '/pulse/v1/census/facilities/{facilityId}/checkout',
            searchQuery:
                '/pulse/v1/census/facilities/{facilityId}/checkedOutPatients',
        },
    },
    epraPreferences: {
        retrieveEpraPreferences:
            '/common/v1/tenants/{tenantId}/settings/epraPreferences',
    },
};
