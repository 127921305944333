export default function checkInServiceMock({ request, resolve }) {
    const patients = request.data;

    // make a random number of patients successes
    const numberSuccesses = Math.floor(Math.random() * patients.length + 1);

    const succeeded = patients.slice(0, numberSuccesses);

    let failed = [];
    if (numberSuccesses !== patients.length) {
        failed = patients.slice(numberSuccesses);
    }

    setTimeout(() => {
        resolve({
            succeeded,
            failed,
        });
    }, 3000);
}
