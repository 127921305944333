/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Button from '@audacious/components/components/Button';
import { Text } from '@audacious/components/components/Typography';
import { Container, Row, Column } from '@audacious/components/components/Grid';
import {
    PageContainer,
    PageTitle,
    PageContainerGroup,
} from '@audacious/components/components/Page';

import Data, {
    DataTextProperty,
    DataSelectProperty,
    DataList,
    DataDateProperty,
    DataMaskProperty,
} from '@audacious/components/components/Data';
import isNil from 'lodash/isNil';
import GenderOptions from '../../../common/gender-options';
import StatesOptions from '../../../common/usa-states-options';
import validateDob from '../../../common/validate-dob';
import zipCodeMask from '../../../common/zipcode-mask';
import socialMask from '../../../common/social-mask';
import {
    reviewCheckIns,
    resetCheckIns,
} from '../../../actions/check-in-actions';
import { checkInsPropType } from './check-in-prop-type';
import {
    namePattern,
    zipCodePattern,
    countryCodePattern,
    phonePattern,
    basicPattern,
    socialPattern,
} from '../../../constants/reg-ex-patterns';
import { MIN_DATE, MAX_DATE } from '../../../constants/dates';
import {
    INVALID_VALUE_MSG,
    INVALID_CHARACTERS_MSG,
} from '../../../constants/error-messages';
import scrollTopTop from '../../../common/scroll-to-top';
import './check-in.scss';

const baseValue = {
    checkIns: [
        {
            issues: [],
            patient: {
                countryCode: '+01',
                dob: null,
            },
        },
    ],
};

class CheckInStart extends React.Component {
    constructor(props) {
        super(props);

        this.headingGroupRef = React.createRef();
        this.dataRef = React.createRef();
        this.firstNameRef = React.createRef();

        this.handleValidateStart = this.handleValidateStart.bind(this);
        this.handleValidateEnd = this.handleValidateEnd.bind(this);
        this.handleExecuteStart = this.handleExecuteStart.bind(this);
        this.handleExecute = this.handleExecute.bind(this);
        this.handleDoneClick = this.handleDoneClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);

        let { checkIns: initialValue } = props;

        if (isNil(initialValue)) {
            initialValue = baseValue;
        } else {
            initialValue = { checkIns: initialValue };
        }

        this.state = {
            working: false,
            initialValue,
        };
    }

    componentDidMount() {
        scrollTopTop(this.headingGroupRef.current);
    }

    handleValidateStart() {
        this.setState({
            working: true,
        });
    }

    handleValidateEnd() {
        this.setState({
            working: false,
        });
    }

    handleExecuteStart(value, results) {
        if (!isNil(results)) {
            return false;
        }

        this.setState({
            working: true,
        });

        return true;
    }

    handleExecute(value) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(reviewCheckIns, value.checkIns);
    }

    handleDoneClick() {
        this.dataRef.current.execute();
    }

    handleCancelClick() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(resetCheckIns);
    }

    renderContent() {
        return (
            <DataList path="checkIns">
                <Container gutter="16">
                    <Row gutter="16">
                        <Column width={[null, '4', null, 'fill']}>
                            <DataTextProperty
                                ref={this.firstNameRef}
                                id="firstName"
                                label="First Name*"
                                aria-label="First Name"
                                path="patient.firstName"
                                required={[true, INVALID_VALUE_MSG]}
                                minLength={[2, INVALID_VALUE_MSG]}
                                maxLength={[50, INVALID_VALUE_MSG]}
                                pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                        <Column width={[null, '4', null, 'fill']}>
                            <DataTextProperty
                                id="middleName"
                                label="Middle Name"
                                aria-label="Middle Name"
                                path="patient.middleName"
                                maxLength={[50, INVALID_VALUE_MSG]}
                                pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                        <Column width={[null, '4', null, 'fill']}>
                            <DataTextProperty
                                id="lastName"
                                label="Last Name*"
                                aria-label="Last Name"
                                path="patient.lastName"
                                required={[true, INVALID_VALUE_MSG]}
                                minLength={[2, INVALID_VALUE_MSG]}
                                maxLength={[50, INVALID_VALUE_MSG]}
                                pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                        <Column width={[null, 'static:170']}>
                            <DataDateProperty
                                id="dob"
                                label="Date of Birth*"
                                path="patient.dob"
                                required={[true, INVALID_VALUE_MSG]}
                                fromDate={MIN_DATE}
                                toDate={MAX_DATE}
                                attributes={{
                                    validDate: {
                                        enabled: true,
                                        onValidate: validateDob,
                                    },
                                }}
                            />
                        </Column>
                        <Column width={[null, 'static:140']}>
                            <DataSelectProperty
                                id="gender"
                                label="Gender*"
                                aria-label="Gender"
                                path="patient.gender"
                                options={GenderOptions}
                                required={[true, INVALID_VALUE_MSG]}
                            />
                        </Column>
                        <Column width={[null, 'static:180']}>
                            <DataMaskProperty
                                id="ssn"
                                label="Social Security Number"
                                aria-label="Social Security Number"
                                path="patient.ssn"
                                mask={socialMask}
                                pattern={[socialPattern, INVALID_VALUE_MSG]}
                            />
                        </Column>
                    </Row>
                    <Row gutter="16">
                        <Column width={[null, null, '6']}>
                            <DataTextProperty
                                id="addressLine1"
                                label="Address Line 1"
                                aria-label="Address Line 1"
                                path="patient.address1"
                                minLength={[2, INVALID_VALUE_MSG]}
                                maxLength={[255, INVALID_VALUE_MSG]}
                                pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                        <Column width={[null, null, '6']}>
                            <DataTextProperty
                                id="addressLine2"
                                label="Address Line 2"
                                aria-label="Address Line 2"
                                path="patient.address2"
                                minLength={[2, INVALID_VALUE_MSG]}
                                maxLength={[255, INVALID_VALUE_MSG]}
                                pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                    </Row>
                    <Row gutter="16">
                        <Column width={[null, '7', 'fill']}>
                            <DataTextProperty
                                id="city"
                                label="City"
                                aria-label="City"
                                path="patient.city"
                                minLength={[2, INVALID_VALUE_MSG]}
                                maxLength={[35, INVALID_VALUE_MSG]}
                                pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                            />
                        </Column>
                        <Column width={[null, '5', '3']}>
                            <DataSelectProperty
                                id="state"
                                label="State"
                                aria-label="State"
                                path="patient.state"
                                options={StatesOptions}
                            />
                        </Column>
                        <Column width={[null, 'static:150']}>
                            <DataMaskProperty
                                id="zip"
                                label="Zip Code*"
                                aria-label="Zip Code"
                                required={[true, INVALID_VALUE_MSG]}
                                path="patient.zip"
                                mask={zipCodeMask}
                                pattern={[zipCodePattern, INVALID_VALUE_MSG]}
                            />
                        </Column>
                        <Column width={[null, 'static:115']}>
                            <DataMaskProperty
                                id="countryCode"
                                label="Country Code*"
                                aria-label="Country Code"
                                required={[true, INVALID_VALUE_MSG]}
                                path="patient.countryCode"
                                pattern={[
                                    countryCodePattern,
                                    INVALID_VALUE_MSG,
                                ]}
                                mask={['+', /[1-9]/, /\d/, /\d/, /\d/]}
                            />
                        </Column>
                        <Column width={[null, 'static:190']}>
                            <DataMaskProperty
                                id="phone"
                                label="Area and Phone Number*"
                                aria-label="Area and Phone Number"
                                required={[true, INVALID_VALUE_MSG]}
                                path="patient.phone"
                                mask={[
                                    /[1-9]/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    '-',
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                    /\d/,
                                ]}
                                pattern={[phonePattern, INVALID_VALUE_MSG]}
                            />
                        </Column>
                    </Row>
                </Container>
            </DataList>
        );
    }

    render() {
        const { working, initialValue } = this.state;
        const spinner = null;

        return (
            <>
                <PageTitle pageName="Check-In" />
                {spinner}
                <PageContainer asCard allowScroll>
                    <PageContainerGroup className="ec-required-text-group">
                        <Row gutter="16">
                            <Column>
                                <div>
                                    <Text
                                        id="required-fields-message"
                                        color="danger"
                                    >
                                        Required Fields*
                                    </Text>
                                </div>
                            </Column>
                        </Row>
                    </PageContainerGroup>
                    <Data
                        ref={this.dataRef}
                        baseValue={initialValue}
                        validateOnBlur
                        validateOnExecute
                        showResultsOnTouch
                        showResultsOnExecute
                        onValidateStart={this.handleValidateStart}
                        onValidateEnd={this.handleValidateEnd}
                        onExecuteStart={this.handleExecuteStart}
                        onExecute={this.handleExecute}
                    >
                        {this.renderContent()}
                    </Data>
                    <ButtonGroup className="footer-group">
                        <Button
                            id="checkInNext"
                            color="secondary"
                            variant="fill"
                            disabled={working}
                            onClick={this.handleDoneClick}
                        >
                            Next
                        </Button>
                        <Button
                            id="checkInCancel"
                            color="secondary"
                            variant="opaque"
                            onClick={this.handleCancelClick}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </PageContainer>
            </>
        );
    }
}

CheckInStart.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    checkIns: checkInsPropType,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckInStart.defaultProps = {
    checkIns: null,
};

export default withRouter(applyFluxibleContext(CheckInStart));
