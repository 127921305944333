import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import formatPatient from '../common/util/format-patient';

const initialState = {
    checkedInPatients: [],
    isGettingCheckedInPatients: false,
    checkedOutPatients: [],
    isGettingCheckedOutPatients: false,
    isUpdatingCheckInPatient: false,
    updateError: null,
    newCheckins: [],
    failedNewCheckins: [],
    displayNewPatientNotification: false,
    displayUpdatePatientNotification: false,
    updatedCheckinPatient: null,
};

function setNullToEmptyPatientProperties(patient) {
    // for now adding only few properties which causing validation issues
    return {
        ...patient,
        middleName: !isEmpty(patient.middleName) ? patient.middleName : null,
        address1: !isEmpty(patient.address1) ? patient.address1 : null,
        address2: !isEmpty(patient.address2) ? patient.address2 : null,
        city: !isEmpty(patient.city) ? patient.city : null,
        countryCode: !isEmpty(patient.countryCode)
            ? patient.countryCode
            : '+01',
    };
}

class RosterStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    getCheckedInPatientsStart() {
        this.setState({
            ...this.state,
            isGettingCheckedInPatients: true,
        });
    }

    getCheckedInPatientsSuccess(data) {
        const { checkins } = data;

        const processed = map(checkins, patient => ({
            id: patient.mrn,
            patient: setNullToEmptyPatientProperties(patient),
            formatted: formatPatient(patient),
        }));

        this.setState({
            ...this.state,
            checkedInPatients: processed,
            isGettingCheckedInPatients: false,
            displayNewPatientNotification: true,
        });
    }

    getCheckedInPatientsFailed() {
        this.setState({
            ...this.state,
            isGettingCheckedInPatients: false,
        });
    }

    editCheckInPatientStart() {
        this.setState({
            ...this.state,
            isUpdatingCheckInPatient: true,
            updateError: null,
        });
    }

    checkinStart() {
        this.setState({
            ...this.state,
            newCheckins: [],
            failedNewCheckins: [],
        });
    }

    editCheckInPatientSuccess(results) {
        const newState = this.getState();

        const { checkedInPatients } = newState;
        const updatedCheckInPatient = results.succeeded[0];

        const index = findIndex(
            checkedInPatients,
            checkedInPatient =>
                updatedCheckInPatient.mrn === checkedInPatient.id,
        );

        if (index >= 0) {
            checkedInPatients[index] = {
                id: updatedCheckInPatient.mrn,
                patient: updatedCheckInPatient,
                formatted: formatPatient({
                    ...updatedCheckInPatient,
                    dob: `${updatedCheckInPatient.dob.year}-${updatedCheckInPatient.dob.month}-${updatedCheckInPatient.dob.day}`,
                }),
            };
        }

        this.setState({
            ...newState,
            isUpdatingCheckInPatient: false,
            updatedCheckinPatient: updatedCheckInPatient,
            displayUpdatePatientNotification: true,
        });
    }

    editCheckInPatientFailed(error) {
        this.setState({
            isUpdatingCheckInPatient: false,
            updateError: error || { message: 'Unknown error' },
        });
    }

    stopDisplayNewPatientNotification() {
        this.setState({
            ...this.state,
            displayNewPatientNotification: false,
            newCheckins: [],
            failedNewCheckins: [],
        });
    }

    stopDisplayUpdatePatientNotification() {
        this.setState({
            ...this.state,
            updatedCheckinPatient: null,
            displayUpdatePatientNotification: false,
        });
    }

    stopDisplayUpdateErrorNotification() {
        this.setState({
            ...this.state,
            updateError: false,
        });
    }

    markNewCheckedInPatient(checkedInPatientObj) {
        const { succeeded, failed } = checkedInPatientObj;
        const newState = this.getState();
        const { newCheckins, failedNewCheckins } = newState;

        if (!isEmpty(succeeded)) {
            forEach(succeeded, checkedInPatient => {
                newCheckins.push(checkedInPatient.mrn);
            });
        }

        if (!isEmpty(failed)) {
            forEach(failed, failedCheckedInPatient => {
                newCheckins.push(failedCheckedInPatient.lastName); // Failed checkins don't have an mrn
            });
        }

        this.setState({
            newCheckins,
            failedNewCheckins,
            displayNewPatientNotification: false,
        });
    }
}

RosterStore.storeName = 'RosterStore';
RosterStore.handlers = {
    GET_CHECKED_IN_PATIENTS_START: 'getCheckedInPatientsStart',
    GET_CHECKED_IN_PATIENTS_SUCCESS: 'getCheckedInPatientsSuccess',
    GET_CHECKED_IN_PATIENTS_FAILED: 'getCheckedInPatientsFailed',
    EDIT_CHECK_IN_PATIENT_START: 'editCheckInPatientStart',
    EDIT_CHECK_IN_PATIENT_SUCCESS: 'editCheckInPatientSuccess',
    EDIT_CHECK_IN_PATIENT_FAILED: 'editCheckInPatientFailed',
    MARK_NEW_CHECKED_IN_PATIENT: 'markNewCheckedInPatient',
    CHECK_IN_START: 'checkinStart',
    STOP_DISPLAY_NEW_PATIENT_NOTIFICATION: 'stopDisplayNewPatientNotification',
    STOP_DISPLAY_UPDATE_PATIENT_NOTIFICATION:
        'stopDisplayUpdatePatientNotification',
    STOP_DISPLAY_UPDATE_ERROR_NOTIFICATION:
        'stopDisplayUpdateErrorNotification',
};

export default RosterStore;
