import map from 'lodash/map';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import StatesOptions from './usa-states-options';

function validateState(value) {
    const usaStates = map(StatesOptions, state => toLower(state.key));

    if (!value) {
        return null;
    }

    if (!includes(usaStates, toLower(value))) {
        return 'Enter a valid state';
    }

    return null;
}

export default validateState;
