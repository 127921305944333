import { stores as facilityStores } from '@audacious/pulse-facility-selection';
import CheckInWorkflowStore from './check-in-workflow-store';
import CheckOutWorkflowStore from './check-out-workflow-store';
import epraPreferencesStore from './epra-preferences-store';
import rosterStore from './roster-store';
import checkedOutRosterStore from './checked-out-roster-store';

export default [
    CheckInWorkflowStore,
    CheckOutWorkflowStore,
    epraPreferencesStore,
    rosterStore,
    checkedOutRosterStore,
    ...facilityStores,
];
