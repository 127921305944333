import CommonStore from '@audacious/web-common/fluxible/CommonStore';

const initialState = {
    preferences: null,
    isRetrieving: false,
};

class EpraPreferencesStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    retrievePreferencesStart() {
        this.setState({
            ...this.getState(),
            isRetrieving: true,
        });
    }

    retrievePreferencesFinish(preferences) {
        this.setState({
            ...this.getState(),
            preferences,
            isRetrieving: false,
        });
    }

    retrievePreferencesFailed() {
        this.setState({
            ...this.getState(),
            isRetrieving: false,
        });
    }
}

EpraPreferencesStore.storeName = 'EpraPreferencesStore';
EpraPreferencesStore.handlers = {
    RETRIEVE_EPRA_PREFERENCES_START: 'retrievePreferencesStart',
    RETRIEVE_EPRA_PREFERENCES_FINISH: 'retrievePreferencesFinish',
    RETRIEVE_EPRA_PREFERENCES_FAILED: 'retrievePreferencesFailed',
};

export default EpraPreferencesStore;
