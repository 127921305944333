/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes, { string } from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import { withRouter } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import Button from '@audacious/components/components/Button';
import Menu, { MenuButton } from '@audacious/components/components/Menu';
import { AnchorButton } from '@audacious/components/components/DropDown';
import PageNotification from '@audacious/components/components/PageNotification';
import { faMemoCircleCheck } from '@audacious/icons/light/faMemoCircleCheck';
import { faPersonWalkingArrowRight } from '@audacious/icons/light/faPersonWalkingArrowRight';
import {
    stopDisplayNewPatientNotification,
    stopDisplayUpdateErrorNotification,
} from '../../../actions/check-in-actions';
import CheckedInRoster from './checked-in-roster';
import CheckedOutRoster from './checked-out-roster';
import patientPropType from './patient-prop-type';
import './main-page.scss';

const CHECKED_IN_TAB = 'checked-in';
const CHECKED_OUT_TAB = 'checked-out';

class MainPageContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            // pageContentTab: CHECKED_IN_TAB,
            showBulkCheckinDrawer: false,
            // stopShowingNewPatientNotifications: true,
        };

        this.handlePageContentTabChange = this.handlePageContentTabChange.bind(
            this,
        );
        this.handleCheckInClick = this.handleCheckInClick.bind(this);
        this.handleCheckOutClick = this.handleCheckOutClick.bind(this);
        this.handleBulkCheckin = this.handleBulkCheckin.bind(this);
        this.cancelBulkCheckinDrawer = this.cancelBulkCheckinDrawer.bind(this);
        this.handleResetNotifications = this.handleResetNotifications.bind(
            this,
        );
        this.hideErrorPopup = this.hideErrorPopup.bind(this);
    }

    componentDidMount() {
        const {
            tenantId,
            facilityId,
            preventPatientsLoading,
            bulkData,
            fluxibleContext: {
                service: {
                    getCheckedInPatients,
                    getCheckedOutPatients,
                    retrieveEpraPreferences,
                },
            },
        } = this.props;

        if (facilityId && tenantId && !preventPatientsLoading) {
            getCheckedInPatients({ options: { tenantId, facilityId } });
            getCheckedOutPatients({
                options: { tenantId, facilityId },
            });
        }

        if (bulkData.drawerOpen) {
            this.setState({ showBulkCheckinDrawer: true });
        }

        retrieveEpraPreferences({
            options: { tenantId },
        });
    }

    componentDidUpdate(
        prevProps,
        // , prevState
    ) {
        const { step } = this.props;
        const { step: prevStep } = prevProps;
        // const { pageContentTab: prevPageContentTab } = prevState;
        // const { stopShowingNewPatientNotifications } = this.state;

        if (isEqual(step, 'bulk-reviewed') && isEqual(prevStep, 'started')) {
            const { history } = this.props;

            history.push('/check-in');
        }

        // if (
        //     !isEqual(prevPageContentTab, 'checked-in') &&
        //     !stopShowingNewPatientNotifications
        // ) {
        //     this.setState({ stopShowingNewPatientNotifications: true });
        // }
    }

    handleBulkCheckin() {
        this.setState({ showBulkCheckinDrawer: true });
    }

    handleCheckInClick() {
        const { history } = this.props;

        history.push('/check-in');
    }

    handleCheckOutClick() {
        const { history } = this.props;

        history.push('/check-out');
    }

    handleResetNotifications() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;
        executeAction(stopDisplayNewPatientNotification);
    }

    handlePageContentTabChange(value) {
        const { history } = this.props;

        history.push(`/home/${value}`);

        // this.setState({ pageContentTab: value });
    }

    cancelBulkCheckinDrawer() {
        this.setState({ showBulkCheckinDrawer: false });
    }

    hideErrorPopup() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(stopDisplayUpdateErrorNotification);
    }

    render() {
        const {
            // pageContentTab,
            showBulkCheckinDrawer,
        } = this.state;
        const {
            checkedInPatients,
            isGettingCheckedInPatients,
            // checkedOutPatients,
            // isGettingCheckedOutPatients,
            isUpdating,
            updateError,
            bulkData,
            newCheckins,
            failedNewCheckins,
            displayNewPatientNotification,
            updatedCheckinPatient,
            displayUpdatePatientNotification,
            userName,
            facilityId,
            facilityName,
            locationName,
            allowPrint,
            listType,
        } = this.props;

        let contentRender = null;
        let errorNotification = null;

        if (listType === CHECKED_IN_TAB) {
            errorNotification = updateError ? (
                <PageNotification
                    actions={[
                        {
                            label: 'Okay',
                            matchColor: false,
                            onClick: this.hideErrorPopup,
                        },
                    ]}
                    showIcon
                    color="danger"
                    heading="Something went wrong"
                    message="We were unable to save your changes. Please try again at a later time."
                    className="ec-error-notification"
                />
            ) : null;

            contentRender = (
                <CheckedInRoster
                    patients={checkedInPatients}
                    isLoading={isGettingCheckedInPatients}
                    isUpdating={isUpdating}
                    updateError={updateError}
                    facilityId={facilityId}
                    showBulkCheckinDrawer={showBulkCheckinDrawer}
                    cancelBulkCheckinDrawer={this.cancelBulkCheckinDrawer}
                    bulkData={bulkData}
                    newCheckins={newCheckins}
                    displayNewPatientNotification={
                        displayNewPatientNotification
                    }
                    updatedCheckinPatient={updatedCheckinPatient}
                    displayUpdatePatientNotification={
                        displayUpdatePatientNotification
                    }
                    userName={userName}
                    facilityName={facilityName}
                    locationName={locationName}
                    allowPrint={allowPrint}
                />
            );
        } else {
            contentRender = (
                <CheckedOutRoster
                    // patients={checkedOutPatients}
                    // isLoading={isGettingCheckedOutPatients}
                    showBulkCheckinDrawer={showBulkCheckinDrawer}
                    cancelBulkCheckinDrawer={this.cancelBulkCheckinDrawer}
                    bulkData={bulkData}
                    userName={userName}
                    facilityName={facilityName}
                    locationName={locationName}
                    allowPrint={allowPrint}
                />
            );
        }

        const newCheckinsLength = newCheckins.length;
        const totalCheckinsLength = failedNewCheckins.length;

        if (
            (newCheckinsLength > 0 &&
                !isGettingCheckedInPatients &&
                displayNewPatientNotification) ||
            (!isUpdating &&
                !isNil(updatedCheckinPatient) &&
                displayUpdatePatientNotification)
        ) {
            setTimeout(() => {
                this.handleResetNotifications();
            }, 9000);
        }

        const headerFeature = (
            <div className="main-page-title-header-feature">
                <Menu
                    id="ec-check-in-menu"
                    Anchor={AnchorButton}
                    anchorProps={{
                        color: 'primary',
                        variant: 'outline',
                        children: 'Check-In',
                        leftIcon: faMemoCircleCheck,
                    }}
                >
                    <MenuButton
                        id="ec-check-in-menu-single"
                        onClick={this.handleCheckInClick}
                    >
                        Single Check-In
                    </MenuButton>
                    <MenuButton
                        id="ec-check-in-menu-bulk"
                        onClick={this.handleBulkCheckin}
                    >
                        Bulk Check-In
                    </MenuButton>
                </Menu>
                <Button
                    id="ec-check-out-button"
                    color="primary"
                    variant="outline"
                    onClick={this.handleCheckOutClick}
                    leftIcon={faPersonWalkingArrowRight}
                >
                    Check-Out
                </Button>
            </div>
        );

        return (
            <>
                {newCheckinsLength > 0 &&
                !isGettingCheckedInPatients &&
                displayNewPatientNotification ? (
                    <PageNotification
                        className="ec-success-notification"
                        color="success"
                        heading="Check-In Completed"
                        message={`${newCheckinsLength} of ${newCheckinsLength +
                            totalCheckinsLength} successfully checked in.`}
                        showIcon
                    />
                ) : null}
                <PageTitle
                    pageName="Emergency Census"
                    customFeature={headerFeature}
                    selectedTab={listType}
                    tabs={[
                        {
                            id: CHECKED_IN_TAB,
                            heading: 'Checked-In',
                        },
                        {
                            id: CHECKED_OUT_TAB,
                            heading: 'Checked-Out',
                        },
                    ]}
                    onTabSelect={this.handlePageContentTabChange}
                />
                <PageContainer asCard allowScroll className="ec-main-content">
                    {contentRender}
                    {errorNotification}
                </PageContainer>
            </>
        );
    }
}

MainPageContent.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    userName: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    checkedInPatients: PropTypes.arrayOf(patientPropType).isRequired,
    isGettingCheckedInPatients: PropTypes.bool.isRequired,
    // checkedOutPatients: PropTypes.arrayOf(patientPropType).isRequired,
    // isGettingCheckedOutPatients: PropTypes.bool.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    updateError: PropTypes.shape(PropTypes.any),
    bulkData: PropTypes.shape({
        running: PropTypes.bool,
        error: PropTypes.bool,
        drawerOpen: PropTypes.bool,
    }).isRequired,
    step: PropTypes.string,
    newCheckins: PropTypes.arrayOf(string).isRequired,
    failedNewCheckins: PropTypes.arrayOf(string).isRequired,
    displayNewPatientNotification: PropTypes.bool.isRequired,
    updatedCheckinPatient: patientPropType.isRequired,
    displayUpdatePatientNotification: PropTypes.bool.isRequired,
    preventPatientsLoading: PropTypes.bool.isRequired,
    allowPrint: PropTypes.bool.isRequired,
    listType: PropTypes.string.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
        service: {
            getCheckedInPatients: PropTypes.func,
            getCheckedOutPatients: PropTypes.func,
            retrieveEpraPreferences: PropTypes.func,
        },
    }).isRequired,
};

MainPageContent.defaultProps = {
    updateError: null,
    step: null,
};

// eslint-disable-next-line no-class-assign
MainPageContent = withRouter(MainPageContent);

export default connectToStores(
    applyFluxibleContext(MainPageContent),
    ['Session', 'RosterStore', 'CheckInWorkflowStore', 'EpraPreferencesStore'],
    context => {
        const sessionStore = context.getStore('Session');
        const submittedCheckInsStore = context.getStore('CheckInWorkflowStore');
        const rosterStore = context.getStore('RosterStore');
        // const checkedOutRosterStore = context.getStore('CheckedOutRosterStore');

        const user = sessionStore.getUser();

        const userName = isNil(user) ? '' : user.getFullName();
        const tenantId = sessionStore.getTenant().getId();
        const facility = sessionStore.getFacility();
        const facilityId = facility ? facility.getId() : '';
        const facilityName = isNil(facility) ? '' : facility.getName();
        const locationName = isNil(facility) ? '' : facility.getLocationName();

        const rosterState = rosterStore.getState();

        const bulkData = submittedCheckInsStore.getBulkData();
        const step = submittedCheckInsStore.getWorkflowStep();
        const preventPatientsLoading = submittedCheckInsStore.getPreventPatientsLoading();

        const epraPreferencesState = context
            .getStore('EpraPreferencesStore')
            .getState();

        const allowPrint = get(
            epraPreferencesState,
            'preferences.allowPrint',
            false,
        );

        return {
            userName,
            tenantId,
            facilityId,
            facilityName,
            locationName,
            checkedInPatients: rosterState.checkedInPatients,
            isGettingCheckedInPatients: rosterState.isGettingCheckedInPatients,
            // checkedOutPatients: checkedOutRosterStore.getPatients(),
            // isGettingCheckedOutPatients: checkedOutRosterStore.getPatients().isLoading(),
            isUpdating: rosterState.isUpdatingCheckInPatient,
            updateError: rosterState.updateError,
            bulkData,
            step,
            newCheckins: rosterState.newCheckins,
            failedNewCheckins: rosterState.failedNewCheckins,
            updatedCheckinPatient: rosterState.updatedCheckinPatient,
            displayNewPatientNotification:
                rosterState.displayNewPatientNotification,
            displayUpdatePatientNotification:
                rosterState.displayUpdatePatientNotification,
            preventPatientsLoading,
            allowPrint,
        };
    },
);
