import isNil from 'lodash/isNil';

export function startCheckOut(actionContext) {
    actionContext.dispatch('CHECK_OUT_START');
}

export function startDestination(actionContext, selectedPatients) {
    actionContext.dispatch('CHECK_OUT_START_DESTINATION', selectedPatients);
}

export function cancelDestination(actionContext) {
    actionContext.dispatch('CHECK_OUT_CANCEL_DESTINATION');
}

export function startReview(actionContext, selectedPatients) {
    actionContext.dispatch('CHECK_OUT_START_REVIEW', selectedPatients);
}

export function cancelReview(actionContext) {
    actionContext.dispatch('CHECK_OUT_CANCEL_REVIEW');
}

export function startConfirmation(actionContext) {
    actionContext.dispatch('CHECK_OUT_START_CONFIRMATION');
}

export function attemptCheckOut(actionContext, { patients, history }) {
    const {
        service: { createCheckOuts },
        getStore,
    } = actionContext;

    const sessionStore = getStore('Session');

    const tenantId = sessionStore.getTenant().getId();
    const facility = sessionStore.getFacility();
    const facilityId = facility ? facility.getId() : '';

    createCheckOuts(
        {
            options: { tenantId, facilityId },
            data: patients,
        },
        (unused, serviceContext) => {
            if (isNil(serviceContext.error)) {
                history.push('/home/checked-out');
            }
        },
    );
}
