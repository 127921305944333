import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import isNil from 'lodash/isNil';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import Dialog from '@audacious/components/components/Dialog';
import { SubHeading } from '@audacious/components/components/Typography';
import Document, {
    DocumentHeader,
    DocumentBody,
    DocumentFooter,
} from '@audacious/components/components/Document';
import Button from '@audacious/components/components/Button';
import { Row, Column } from '@audacious/components/components/Grid';
import IconButton from '@audacious/components/components/IconButton';
import { faPlus } from '@audacious/icons/regular/faPlus';
import { faXmark } from '@audacious/icons/regular/faXmark';
import DateInput from '@audacious/components/components/DateInput';
import SelectInput from '@audacious/components/components/SelectInput';

import {
    INVALID_VALUE_MSG,
    // INVALID_CHARACTERS_MSG,
} from '../../../../constants/error-messages';
import { MIN_DATE, MAX_DATE } from '../../../../constants/dates';
import './checkout-filter-drawer.scss';

function isValidDate(date) {
    return date instanceof Date && !Number.isNaN(Number(date));
}

class CheckoutFilterDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            checkinStartDate: null,
            checkinEndDate: null,
            checkinStartDatevalid: true,
            checkinEndDatevalid: true,
            checkinStartDateinvalidMessages: [],
            checkinEndDateinvalidMessages: [],
            showSecondDate: false,
            destination: null,
            checkoutStartDate: null,
            checkoutEndDate: null,
            checkoutStartDatevalid: true,
            checkoutEndDatevalid: true,
            checkoutStartDateinvalidMessages: [],
            checkoutEndDateinvalidMessages: [],
        };

        this.handleExecutecheckinStartDate = this.handleExecutecheckinStartDate.bind(
            this,
        );
        this.handleExecutecheckinEndDate = this.handleExecutecheckinEndDate.bind(
            this,
        );
        this.handleExecutecheckoutStartDate = this.handleExecutecheckoutStartDate.bind(
            this,
        );
        this.handleExecutecheckoutEndDate = this.handleExecutecheckoutEndDate.bind(
            this,
        );
        this.handleAddSecondDate = this.handleAddSecondDate.bind(this);
        this.handleRemoveSecondDate = this.handleRemoveSecondDate.bind(this);
        this.handleValidations = this.handleValidations.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
        this.handleExecuteDestination = this.handleExecuteDestination.bind(
            this,
        );
        this.handleAddFourthDate = this.handleAddFourthDate.bind(this);
        this.handleRemoveFourthDate = this.handleRemoveFourthDate.bind(this);
    }

    componentDidUpdate(prevProps) {
        const prevcheckinStartDateInUse = prevProps.checkinStartDateInUse;
        const prevcheckoutStartDateInUse = prevProps.checkoutStartDateInUse;
        const prevDestinationInUse = prevProps.destinationInUse;
        const {
            checkinStartDateInUse,
            checkoutStartDateInUse,
            destinationInUse,
        } = this.props;
        if (isNil(checkinStartDateInUse) && !isNil(prevcheckinStartDateInUse)) {
            this.setState({ checkinStartDate: null, checkinEndDate: null });
        }
        if (
            isNil(checkoutStartDateInUse) &&
            !isNil(prevcheckoutStartDateInUse)
        ) {
            this.setState({ checkoutStartDate: null, checkoutEndDate: null });
        }
        if (isNil(destinationInUse) && !isNil(prevDestinationInUse)) {
            this.setState({ destination: null });
        }
    }

    handleValidations(
        checkinStartDate,
        checkinEndDate,
        checkoutStartDate,
        checkoutEndDate,
    ) {
        const tomorrow = startOfTomorrow();
        const checkinEndDatedate = new Date(checkinEndDate);
        const checkinStartDatedate = new Date(checkinStartDate);
        const checkoutStartDatedate = new Date(checkoutStartDate);
        const checkoutEndDatedate = new Date(checkoutEndDate);
        let checkinStartDateinvalidMessages = [];
        let checkinEndDateinvalidMessages = [];
        let checkoutStartDateinvalidMessages = [];
        let checkoutEndDateinvalidMessages = [];
        let checkinStartDatevalid = true;
        let checkinEndDatevalid = true;
        let checkoutStartDatevalid = true;
        let checkoutEndDatevalid = true;

        if (!isValidDate(checkinStartDatedate)) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Invalid date \n');
        }

        if (!isValidDate(checkinEndDatedate)) {
            checkinEndDatevalid = false;
            checkinEndDateinvalidMessages.push('Invalid date \n');
        }

        if (!isValidDate(checkoutStartDatedate)) {
            checkoutStartDatevalid = false;
            checkoutStartDateinvalidMessages.push('Invalid date \n');
        }

        if (!isValidDate(checkoutEndDatedate)) {
            checkoutEndDatevalid = false;
            checkoutEndDateinvalidMessages.push('Invalid date \n');
        }

        if (checkinEndDate && checkinStartDatedate > checkinEndDatedate) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push(
                'First date cannot be after second date \n',
            );
        }

        if (checkoutEndDate && checkoutStartDatedate > checkoutEndDatedate) {
            checkoutStartDatevalid = false;
            checkoutStartDateinvalidMessages.push(
                'First date cannot be after second date \n',
            );
        }

        if (checkinEndDate && checkinEndDatedate >= tomorrow) {
            checkinEndDatevalid = false;
            checkinEndDateinvalidMessages.push('Future dates are invalid \n');
        }

        if (checkinStartDate && checkinStartDatedate >= tomorrow) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Future dates are invalid \n');
        }

        if (checkoutEndDate && checkoutEndDatedate >= tomorrow) {
            checkoutEndDatevalid = false;
            checkoutEndDateinvalidMessages.push('Future dates are invalid \n');
        }

        if (checkoutStartDate && checkoutStartDatedate >= tomorrow) {
            checkoutStartDatevalid = false;
            checkoutStartDateinvalidMessages.push(
                'Future dates are invalid \n',
            );
        }

        if (
            checkoutStartDate &&
            checkinStartDate &&
            checkoutStartDatedate < checkinStartDatedate
        ) {
            checkoutStartDatevalid = false;
            checkoutStartDateinvalidMessages.push(
                'The Check-Out Date must be the same or later than the Check-In Date. \n',
            );
        }

        if (checkinEndDate && !checkinStartDate) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Required field \n');
        }

        if (checkoutEndDate && !checkoutStartDate) {
            checkoutStartDatevalid = false;
            checkoutStartDateinvalidMessages.push('Required field \n');
        }

        if (checkinStartDatevalid) {
            checkinStartDateinvalidMessages = [];
        }

        if (checkinEndDatevalid) {
            checkinEndDateinvalidMessages = [];
        }

        if (checkoutStartDatevalid) {
            checkoutStartDateinvalidMessages = [];
        }

        if (checkoutEndDatevalid) {
            checkoutEndDateinvalidMessages = [];
        }

        this.setState({
            checkinStartDate,
            checkinEndDate,
            checkinStartDatevalid,
            checkinEndDatevalid,
            checkinStartDateinvalidMessages,
            checkinEndDateinvalidMessages,
            checkoutStartDate,
            checkoutEndDate,
            checkoutStartDatevalid,
            checkoutEndDatevalid,
            checkoutStartDateinvalidMessages,
            checkoutEndDateinvalidMessages,
        });
    }

    handleExecutecheckinStartDate(value) {
        const {
            checkinEndDate,
            checkoutStartDate,
            checkoutEndDate,
        } = this.state;
        this.handleValidations(
            value,
            checkinEndDate,
            checkoutStartDate,
            checkoutEndDate,
        );
    }

    handleExecutecheckinEndDate(value) {
        const {
            checkinStartDate,
            checkoutStartDate,
            checkoutEndDate,
        } = this.state;
        this.handleValidations(
            checkinStartDate,
            value,
            checkoutStartDate,
            checkoutEndDate,
        );
    }

    handleExecutecheckoutStartDate(value) {
        const {
            checkinStartDate,
            checkinEndDate,
            checkoutEndDate,
        } = this.state;
        this.handleValidations(
            checkinStartDate,
            checkinEndDate,
            value,
            checkoutEndDate,
        );
    }

    handleExecutecheckoutEndDate(value) {
        const {
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
        } = this.state;
        this.handleValidations(
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
            value,
        );
    }

    handleExecuteDestination(value) {
        this.setState({ destination: value });
    }

    handleResetFilters() {
        this.setState({
            checkinStartDate: null,
            checkinEndDate: null,
            checkoutStartDate: null,
            checkoutEndDate: null,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
            checkinStartDatevalid: true,
            checkinStartDateinvalidMessages: [],
            checkoutStartDatevalid: true,
            checkoutStartDateinvalidMessages: [],
            checkoutEndDatevalid: true,
            checkoutEndDateinvalidMessages: [],
            showSecondDate: false,
            showFourthDate: false,
            destination: null,
        });
    }

    handleOnCancel() {
        const {
            onCancel,
            checkinStartDateInUse,
            checkinEndDateInUse,
            checkoutStartDateInUse,
            checkoutEndDateInUse,
            destinationInUse,
        } = this.props;
        this.setState({
            checkinStartDate: checkinStartDateInUse,
            checkinEndDate: checkinEndDateInUse,
            checkoutStartDate: checkoutStartDateInUse,
            checkoutEndDate: checkoutEndDateInUse,
            destination: destinationInUse,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
            checkinStartDatevalid: true,
            checkinStartDateinvalidMessages: [],
            showSecondDate: false,
        });
        onCancel();
    }

    handleAddSecondDate() {
        this.setState({ showSecondDate: true });
    }

    handleRemoveSecondDate() {
        const {
            checkinStartDate,
            checkoutStartDate,
            checkoutEndDate,
        } = this.state;
        this.setState({
            showSecondDate: false,
            checkinEndDate: null,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
        });
        this.handleValidations(
            checkinStartDate,
            null,
            checkoutStartDate,
            checkoutEndDate,
        );
    }

    handleAddFourthDate() {
        this.setState({ showFourthDate: true });
    }

    handleRemoveFourthDate() {
        const {
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
        } = this.state;
        this.setState({
            showFourthDate: false,
            checkoutEndDate: null,
            checkoutEndDatevalid: true,
            checkoutEndDateinvalidMessages: [],
        });
        this.handleValidations(
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
            null,
        );
    }

    render() {
        const {
            open,
            onConfirm,
            resetFilters,
            checkinEndDateInUse,
            checkoutEndDateInUse,
        } = this.props;

        const {
            checkinStartDate,
            checkinEndDate,
            showSecondDate,
            checkinStartDatevalid,
            checkinEndDatevalid,
            checkinEndDateinvalidMessages,
            checkinStartDateinvalidMessages,
            destination,
            checkoutStartDate,
            checkoutEndDate,
            showFourthDate,
            checkoutStartDatevalid,
            checkoutEndDatevalid,
            checkoutStartDateinvalidMessages,
            checkoutEndDateinvalidMessages,
        } = this.state;

        if (
            resetFilters &&
            (!isNil(checkinStartDate) ||
                !isNil(checkoutStartDate) ||
                !isNil(destination))
        ) {
            this.handleResetFilters();
        }

        const showSecondDateRender = showSecondDate || checkinEndDateInUse;
        const showFourthDateRender = showFourthDate || checkoutEndDateInUse;

        const checkinStartDateWidth = showSecondDateRender ? '5' : '10';
        const checkoutStartDateWidth = showFourthDateRender ? '5' : '10';

        return (
            <Dialog
                open={open}
                size="sm"
                variant="drawer-right"
                className="event-details"
            >
                <Document size="sm">
                    <DocumentHeader>
                        <SubHeading level="4">Filters</SubHeading>
                    </DocumentHeader>
                    <DocumentBody>
                        <Row gutter="16">
                            <Column width="12">
                                <SelectInput
                                    id="checkout-destination-filter-dropdown"
                                    label="Check-Out Destination"
                                    onChange={this.handleExecuteDestination}
                                    options={['Home', 'Deceased', 'Other']}
                                    value={destination}
                                    placeholder="Select"
                                />
                            </Column>
                        </Row>
                        <Row gutter="16">
                            <Column width={checkinStartDateWidth}>
                                <DateInput
                                    id="checkout-checkin-start-date"
                                    label="Check-In Date"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={
                                        this.handleExecutecheckinStartDate
                                    }
                                    value={checkinStartDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    invalid={!checkinStartDatevalid}
                                    messages={{
                                        invalid: checkinStartDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showSecondDateRender}>
                                <div className="ec-date-dash">-</div>
                            </Column>
                            <Column width="2" hidden={showSecondDateRender}>
                                <IconButton
                                    id="checkout-add-checkin-end-date"
                                    icon={faPlus}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.handleAddSecondDate}
                                    hidden={showSecondDateRender}
                                />
                            </Column>
                            <Column width="5">
                                <DateInput
                                    id="checkout-checkin-end-date"
                                    label="&nbsp;"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={this.handleExecutecheckinEndDate}
                                    value={checkinEndDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    hidden={!showSecondDateRender}
                                    invalid={!checkinEndDatevalid}
                                    messages={{
                                        invalid: checkinEndDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showSecondDateRender}>
                                <IconButton
                                    id="checkout-remove-checkin-end-date"
                                    icon={faXmark}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.handleRemoveSecondDate}
                                    hidden={!showSecondDateRender}
                                />
                            </Column>
                        </Row>
                        <Row gutter="16">
                            <Column width={checkoutStartDateWidth}>
                                <DateInput
                                    id="checkout-checkout-start-date"
                                    label="Check-Out Date"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={
                                        this.handleExecutecheckoutStartDate
                                    }
                                    value={checkoutStartDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    invalid={!checkoutStartDatevalid}
                                    messages={{
                                        invalid: checkoutStartDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showFourthDateRender}>
                                <div className="ec-date-dash">-</div>
                            </Column>
                            <Column width="2" hidden={showFourthDateRender}>
                                <IconButton
                                    id="checkout-add-checkout-end-date"
                                    icon={faPlus}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.handleAddFourthDate}
                                    hidden={showFourthDateRender}
                                />
                            </Column>
                            <Column width="5">
                                <DateInput
                                    id="checkout-checkout-end-date"
                                    label="&nbsp;"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={this.handleExecutecheckoutEndDate}
                                    value={checkoutEndDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    hidden={!showFourthDateRender}
                                    invalid={!checkoutEndDatevalid}
                                    messages={{
                                        invalid: checkoutEndDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showFourthDateRender}>
                                <IconButton
                                    id="checkout-remove-chckout-end-date"
                                    icon={faXmark}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.handleRemoveFourthDate}
                                    hidden={!showFourthDateRender}
                                />
                            </Column>
                        </Row>
                    </DocumentBody>
                    <DocumentFooter>
                        <Button
                            id="checkoutFiltersDrawerDone"
                            type="submit"
                            color="primary"
                            variant="fill"
                            onClick={() =>
                                onConfirm(
                                    checkinStartDate,
                                    checkinEndDate,
                                    destination,
                                    checkoutStartDate,
                                    checkoutEndDate,
                                )
                            }
                            disabled={
                                (!checkinStartDate &&
                                    !destination &&
                                    !checkoutStartDate) ||
                                !checkinStartDatevalid ||
                                !checkinEndDatevalid ||
                                !checkoutStartDatevalid ||
                                !checkoutEndDatevalid
                            }
                        >
                            Done
                        </Button>
                        <Button
                            color="primary"
                            variant="opaque"
                            id="checkoutFiltersDrawerClose"
                            onClick={() => this.handleOnCancel()}
                            focusOnMount
                        >
                            Cancel
                        </Button>
                    </DocumentFooter>
                </Document>
            </Dialog>
        );
    }
}

CheckoutFilterDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    resetFilters: PropTypes.bool.isRequired,
    checkinStartDateInUse: PropTypes.string,
    checkinEndDateInUse: PropTypes.string,
    checkoutStartDateInUse: PropTypes.string,
    checkoutEndDateInUse: PropTypes.string,
    destinationInUse: PropTypes.string,
};

CheckoutFilterDrawer.defaultProps = {
    checkinStartDateInUse: null,
    checkinEndDateInUse: null,
    checkoutStartDateInUse: null,
    checkoutEndDateInUse: null,
    destinationInUse: null,
};

export default CheckoutFilterDrawer;
