import get from 'lodash/get';

export default function runSearchQuery(context, input) {
    const { getStore, service } = context;

    const { newPagination = {}, search = '', options = {} } = input;

    const exportType = get(options, 'exportType');

    const checkOutWorkflowStore = getStore('CheckOutWorkflowStore');

    const pagination = checkOutWorkflowStore.getPagination();

    let page = newPagination.page || pagination.page;
    let pageSize = newPagination.pageSize || pagination.pageSize;

    if (exportType) {
        page = 1;
        pageSize = 100000;
    }
    // const orderBy = checkOutWorkflowStore.getOrderBy();

    service.runSearchQuery({
        params: {
            page,
            pageSize,
            search,
        },
        options,
    });
}
