import forEach from 'lodash/forEach';
import reduce from 'lodash/reduce';
import join from 'lodash/join';
import isEmpty from 'lodash/isEmpty';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import { buildPatientsCSV, downloadCSV } from '../csv/utilities';

export function startCheckIn(actionContext) {
    actionContext.dispatch('CHECK_IN_START');
}

export function reviewCheckIns(actionContext, checkIns) {
    actionContext.dispatch('CHECK_IN_REVIEW', checkIns);
}

export function updateReviewCheckIns(actionContext, checkInPatient) {
    actionContext.dispatch('UPDATE_CHECK_IN_REVIEW', checkInPatient);
}

export function resetCheckIns(actionContext) {
    actionContext.dispatch('RESET_CHECK_IN');
}

export function goBackToPrevious(actionContext) {
    actionContext.dispatch('CHECK_IN_PREVIOUS');
}

export function goBackToHomeWithDrawerOpen(actionContext) {
    actionContext.dispatch('CHECK_IN_DRAWER_OPEN');
}

export function checkinThenGoHome(
    actionContext,
    { tenantId, facilityId, patients, history },
) {
    const {
        service: { createCheckIns },
    } = actionContext;

    // return Promise.fromCallback(() => {
    createCheckIns(
        {
            options: {
                tenantId,
                facilityId,
            },
            data: {
                patients,
            },
        },
        () => {
            history.push('/intermediate');
        },
    );
    // });
}

export function stopDisplayNewPatientNotification(actionContext) {
    actionContext.dispatch('STOP_DISPLAY_NEW_PATIENT_NOTIFICATION');
    actionContext.dispatch('STOP_DISPLAY_UPDATE_PATIENT_NOTIFICATION');
}

export function stopDisplayUpdateErrorNotification(actionContext) {
    actionContext.dispatch('STOP_DISPLAY_UPDATE_ERROR_NOTIFICATION');
}

export function removeInvalidCheckIns(
    actionContext,
    { checkIns, downloadErrorLog },
) {
    const validCheckIns = [];
    const invalidCheckIns = [];
    let maxNumberOfErrors = 0;

    forEach(checkIns, checkIn => {
        if (isEmpty(checkIn.issues)) {
            validCheckIns.push(checkIn);
        } else {
            invalidCheckIns.push(checkIn);

            if (checkIn.issues.length > maxNumberOfErrors) {
                maxNumberOfErrors = checkIn.issues.length;
            }
        }
    });

    actionContext.dispatch('SET_CHECK_INS', validCheckIns);

    if (!downloadErrorLog) {
        return;
    }

    const sessionStore = actionContext.getStore('Session');

    const user = sessionStore.getUser();
    const facility = sessionStore.getFacility();
    const facilityName = facility ? facility.getName() : '';
    const locationName = facility ? facility.getLocationName() : '';

    const firstName = user.getFirstName();
    const lastName = user.getLastName();

    const userName = `${firstName} ${lastName}`;

    let csvString = '';
    csvString += `User Name,${userName}\n`;
    csvString += `Facility,${facilityName}\n`;
    csvString += `Location,${locationName}\n`;
    csvString += `Date,${dateFormatter(new Date().toString(), {
        outFormat: 'MM/DD/YYYY h:mm a',
    })}\n`;
    csvString += '\n';

    const headerPrefix = () => {
        let errorHeaders = '';

        for (
            let headerIndex = 1;
            headerIndex <= maxNumberOfErrors;
            headerIndex += 1
        ) {
            errorHeaders += `Error ${headerIndex}`;

            if (headerIndex < maxNumberOfErrors) {
                errorHeaders += ',';
            }
        }

        return errorHeaders;
    };

    const rowPrefix = patient => {
        const { issues } = patient;

        return reduce(
            issues,
            (acc, issue, issueIndex) => {
                const { label, messages } = issue;

                // eslint-disable-next-line no-param-reassign
                acc += `${label}: ${join(messages, '; ')}`;

                if (issueIndex < issues.length - 1) {
                    // eslint-disable-next-line no-param-reassign
                    acc += ',';
                }

                return acc;
            },
            '',
        );
    };

    csvString += buildPatientsCSV(
        invalidCheckIns,
        'original',
        headerPrefix,
        rowPrefix,
    );

    downloadCSV(csvString, 'imported-checkin-error-log');
}
