/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import PropTypes from 'prop-types';
import formatDate from 'date-fns/format';
import { Row, Column } from '@audacious/components/components/Grid';
import TextInput from '@audacious/components/components/TextInput';
import Button from '@audacious/components/components/Button';
import { SimpleTable } from '@audacious/components/components/Table';
import {
    PageNavigation,
    RecordsPerPageSelector,
} from '@audacious/components/components/Pagination';
import IconButton from '@audacious/components/components/IconButton';
import Spinner from '@audacious/components/components/Spinner';
import { Text } from '@audacious/components/components/Typography';
import Tooltip from '@audacious/components/components/Tooltip';
import RemovableChip from '@audacious/components/components/RemovableChip';
import TableMessage from '@audacious/components/components/TableMessage';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faSquareSliders } from '@audacious/icons/regular/faSquareSliders';
import { faPrint } from '@audacious/icons/solid/faPrint';
import { faArrowDownToLine } from '@audacious/icons/regular/faArrowDownToLine';
import { faPencil } from '@audacious/icons/regular/faPencil';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import Chip from '@audacious/components/components/Chip';
import CheckinFilterDrawer from './checkin-filter-drawer';
import BulkCheckinDrawer from './bulk-checkin-drawer';
import EditDrawer from '../edit-drawer/edit-drawer';
import patientPropType from '../patient-prop-type';
import filterByDateRange from '../../../../common/util/filter-date-range';
import tableItemFilter from '../../../../common/util/table-item-filter';
import exportPatientsCsv from '../../../../common/util/export-patients-csv';
import printPatients from '../../../../common/util/print-patients';
import './checkin-filter-drawer.scss';

class CheckedInRoster extends React.Component {
    constructor(props) {
        super(props);

        this.patientListToPrintOrExport = [];
        this.state = {
            currentPage: 1,
            recordsPerPage: 50,
            filtersOpen: false,
            checkinStartDate: null,
            checkinEndDate: null,
            resetFilters: false,
            resultsFiltered: false,
            patientBeingEdited: null,
            searchValue: '',
        };

        this.handleChangeRecordsPerPage = this.handleChangeRecordsPerPage.bind(
            this,
        );
        this.handleGotoPage = this.handleGotoPage.bind(this);
        this.handleOpenCheckinFilterDrawer = this.handleOpenCheckinFilterDrawer.bind(
            this,
        );
        this.handleCloseCheckinFilterDrawer = this.handleCloseCheckinFilterDrawer.bind(
            this,
        );
        this.handleDateFiltering = this.handleDateFiltering.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.handleEditPatient = this.handleEditPatient.bind(this);
        this.renderEditColumn = this.renderEditColumn.bind(this);
        this.handleCancelEditPatient = this.handleCancelEditPatient.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.COLUMNS = [
            {
                headerBody: 'Last Name',
                cellValuePath: ['patient.lastName', 'patient.mrn'],
                cellFilterPath: 'patient.lastName',
                sortable: true,
                width: '180px',
                renderCell: ([lastName, mrn]) => {
                    const {
                        newCheckins,
                        displayNewPatientNotification,
                        updatedCheckinPatient,
                        displayUpdatePatientNotification,
                    } = this.props;
                    if (
                        (displayNewPatientNotification &&
                            includes(newCheckins, mrn)) ||
                        (updatedCheckinPatient &&
                            updatedCheckinPatient.mrn === mrn &&
                            displayUpdatePatientNotification)
                    ) {
                        const text = displayUpdatePatientNotification
                            ? 'Updated'
                            : 'New';
                        const chipColor = displayUpdatePatientNotification
                            ? 'warn'
                            : 'success';
                        return (
                            <span>
                                <Text weight="semi-bold">{lastName}</Text>
                                <Chip
                                    className="new-checkin-chip"
                                    color={chipColor}
                                    size="sm"
                                >
                                    {text}
                                </Chip>
                            </span>
                        );
                    }
                    return <Text weight="semi-bold">{lastName}</Text>;
                },
            },
            {
                headerBody: 'First Name',
                cellValuePath: 'patient.firstName',
                sortable: true,
                width: '180px',
            },
            {
                headerBody: 'DOB',
                cellValuePath: 'formatted.dobString',
                width: '120px',
            },
            {
                headerBody: 'Gender',
                cellValuePath: 'formatted.gender',
                width: '100px',
                filterable: false,
            },
            {
                headerBody: 'Address',
                cellValuePath: 'formatted.fullAddress',
            },
            {
                headerBody: 'Phone',
                cellValuePath: 'formatted.phone',
                width: '175px',
            },
            {
                headerBody: 'Check-In Date',
                cellValuePath: 'formatted.checkInDate',
                cellSortPath: 'patient.checkinTime',
                width: '155px',
                sortable: true,
            },
            {
                headerBody: 'Edit',
                cellValuePath: '',
                width: '100px',
                renderCell: this.renderEditColumn,
            },
        ];
    }

    componentDidUpdate(prevProps) {
        const { isUpdating } = this.props;
        const { isUpdating: prevUpdating } = prevProps;

        if (!isUpdating && prevUpdating) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                patientBeingEdited: null,
            });
        }
    }

    handleDateFiltering(checkinStartDate, checkinEndDate) {
        this.setState({
            checkinStartDate,
            checkinEndDate,
            filtersOpen: false,
            resultsFiltered: true,
            currentPage: 1,
        });
    }

    handleChangeRecordsPerPage(recordsPerPage, page) {
        this.setState({ recordsPerPage, currentPage: page });
    }

    handleGotoPage(page) {
        this.setState({ currentPage: page });
    }

    handleOpenCheckinFilterDrawer() {
        this.setState({ filtersOpen: true, resetFilters: false });
    }

    handleCloseCheckinFilterDrawer() {
        this.setState({ filtersOpen: false });
    }

    handleEditPatient({ meta }) {
        const patient = meta;

        this.setState({ patientBeingEdited: patient });
    }

    handleCancelEditPatient() {
        this.setState({
            patientBeingEdited: null,
        });
    }

    handleSortChange() {
        this.setState({ currentPage: 1 });
    }

    handleSearchInputChange(searchValue) {
        this.setState({
            searchValue,
            currentPage: 1,
        });
    }

    handleFilterChange(list) {
        this.patientListToPrintOrExport = list;
    }

    handleDownloadClick() {
        const { userName, facilityName, locationName } = this.props;

        const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        exportPatientsCsv({
            patients: this.patientListToPrintOrExport,
            userName,
            facilityName,
            locationName,
            date,
            eventType: 'checked-in',
        });
    }

    handlePrintClick() {
        const { userName, facilityName, locationName } = this.props;

        const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        printPatients({
            patients: this.patientListToPrintOrExport,
            userName,
            facilityName,
            locationName,
            date,
            eventType: 'checked-in',
        });
    }

    resetFilters() {
        this.setState({
            resetFilters: true,
            resultsFiltered: false,
            checkinStartDate: null,
            checkinEndDate: null,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    renderEditColumn(cellValue, patient) {
        return (
            <IconButton
                icon={faPencil}
                size="sm"
                color="primary"
                onClick={this.handleEditPatient}
                onClickMeta={patient}
            />
        );
    }

    renderEditDrawer() {
        const { facilityId, isUpdating, updateError } = this.props;
        const { patientBeingEdited } = this.state;
        const open = !isNil(patientBeingEdited);

        if (!open) {
            return null;
        }

        return (
            <EditDrawer
                initialData={patientBeingEdited}
                onCancel={this.handleCancelEditPatient}
                facilityId={facilityId}
                isUpdating={isUpdating}
                error={updateError}
                isCheckedIn
            />
        );
    }

    render() {
        const {
            currentPage,
            recordsPerPage,
            filtersOpen,
            checkinStartDate,
            checkinEndDate,
            resetFilters,
            resultsFiltered,
            searchValue,
        } = this.state;
        const {
            patients,
            isLoading,
            cancelBulkCheckinDrawer,
            showBulkCheckinDrawer,
            bulkData,
            allowPrint,
        } = this.props;

        let noPatientsMessage =
            !patients || patients.length === 0
                ? 'No one is checked in at this time'
                : null;

        let filteredPatients = resetFilters
            ? patients
            : filterByDateRange(
                  checkinStartDate,
                  checkinEndDate,
                  patients,
                  'patient.checkinTime',
              );

        const noPatients = !filteredPatients || filteredPatients.length === 0;

        if (noPatients && !noPatientsMessage) {
            noPatientsMessage = 'No record matched your Filter(s).';
        }

        filteredPatients = tableItemFilter(
            filteredPatients,
            this.COLUMNS,
            searchValue,
        );

        if (
            (!filteredPatients || filteredPatients.length === 0) &&
            !noPatientsMessage
        ) {
            noPatientsMessage = 'No record matched your search.';
        }

        this.patientListToPrintOrExport = filteredPatients;

        const exportButtons = allowPrint ? (
            <>
                <Tooltip
                    anchor={IconButton}
                    anchorProps={{
                        id: 'checked-in-roster-export-button',
                        icon: faArrowDownToLine,
                        size: 'sm',
                        'aria-label': 'Export CSV',
                        disabled: noPatients,
                    }}
                    onAnchorClick={this.handleDownloadClick}
                    disabled={noPatients}
                >
                    Download
                </Tooltip>
                <Tooltip
                    anchor={IconButton}
                    anchorProps={{
                        id: 'checked-in-roster-print-button',
                        icon: faPrint,
                        size: 'sm',
                        'aria-label': 'Print',
                        disabled: noPatients,
                    }}
                    onAnchorClick={this.handlePrintClick}
                    disabled={noPatients}
                >
                    Print
                </Tooltip>
            </>
        ) : null;

        return (
            <div className="checked-in-roster roster">
                {isLoading ? (
                    <PageContainerGroup>
                        <Spinner
                            id="checked-in-roster-spinner"
                            variant="overlay"
                            spaceFromTop="sm"
                        />
                    </PageContainerGroup>
                ) : null}
                <PageContainerGroup>
                    <Row className="roster-header" gutter="24" enableAfter>
                        <Column width="fill">
                            <Text size="xl" weight="bold">
                                Checked-In Roster
                            </Text>
                        </Column>
                        <Column width="static:268">
                            <TextInput
                                id="checked-in-roster-search-input"
                                size="sm"
                                placeholder="Search"
                                leftIcon={{ icon: faMagnifyingGlass }}
                                disabled={noPatients}
                                value={searchValue}
                                onChange={this.handleSearchInputChange}
                            />
                        </Column>
                    </Row>
                    <Row gutter="24" enableAfter>
                        <Column width="fill">
                            <Button
                                id="checked-in-roster-filters-button"
                                variant="outline"
                                color="secondary"
                                size="sm"
                                leftIcon={faSquareSliders}
                                disabled={noPatients && !checkinStartDate}
                                onClick={() =>
                                    this.handleOpenCheckinFilterDrawer()
                                }
                            >
                                Filters
                            </Button>
                            {resultsFiltered ? (
                                <RemovableChip
                                    id="checkin-filter-remove"
                                    color="orange"
                                    onClear={this.resetFilters}
                                    shade="lighter"
                                    size="lg"
                                    className="ec-chip"
                                    variant="pill"
                                >
                                    <span className="ec-checkin-chip-label">
                                        Check-In Date
                                    </span>{' '}
                                    {checkinEndDate ? (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkinStartDate,
                                                'MM/dd/yyyy',
                                            )}{' '}
                                            -
                                            {formatDate(
                                                checkinEndDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    ) : (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkinStartDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    )}
                                </RemovableChip>
                            ) : null}
                        </Column>
                        <Column width="content">
                            <RecordsPerPageSelector
                                id="checked-in-roster-records-per-page"
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                recordsPerPageOptions={[20, 50, 100]}
                                onChangeRecordsPerPage={
                                    this.handleChangeRecordsPerPage
                                }
                                tooltipText="Total Number of Records"
                            />
                            <PageNavigation
                                id="checked-in-roster-top-page-nav"
                                firstAndLastNav
                                size="sm"
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                onGotoPage={this.handleGotoPage}
                            />
                            {exportButtons}
                        </Column>
                    </Row>
                </PageContainerGroup>
                <SimpleTable
                    id="checked-in-roster-table"
                    items={filteredPatients}
                    columns={this.COLUMNS}
                    minWidth={1200}
                    alwaysShowHeaders
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    stickyColumns={1}
                    initialSortPath="patient.checkinTime"
                    initialSortDescending
                    onSortChange={this.handleSortChange}
                    filterText={searchValue}
                    onFilteredItemsChange={this.handleFilterChange}
                />
                {noPatientsMessage && !isLoading ? (
                    <PageContainerGroup>
                        <TableMessage header={noPatientsMessage} />
                    </PageContainerGroup>
                ) : null}
                <PageContainerGroup className="footer-group">
                    <Row gutter="24">
                        <Column width="fill" />
                        <Column width="content">
                            <PageNavigation
                                id="checked-in-roster-bottom-page-nav"
                                firstAndLastNav
                                jumpToPageNav
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                onGotoPage={this.handleGotoPage}
                            />
                        </Column>
                        <Column width="fill" />
                    </Row>
                </PageContainerGroup>
                <CheckinFilterDrawer
                    open={filtersOpen}
                    onCancel={() => this.handleCloseCheckinFilterDrawer()}
                    onConfirm={this.handleDateFiltering}
                    resetFilters={resetFilters}
                    checkinStartDateInUse={checkinStartDate}
                    checkinEndDateInUse={checkinEndDate}
                />
                <BulkCheckinDrawer
                    open={showBulkCheckinDrawer}
                    onCancel={() => cancelBulkCheckinDrawer()}
                    checkinStartDateInUse={checkinStartDate}
                    checkinEndDateInUse={checkinEndDate}
                    bulkData={bulkData}
                />
                {this.renderEditDrawer()}
            </div>
        );
    }
}

CheckedInRoster.propTypes = {
    userName: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    patients: PropTypes.arrayOf(patientPropType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    facilityId: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    updateError: PropTypes.shape(PropTypes.any),
    showBulkCheckinDrawer: PropTypes.bool.isRequired,
    cancelBulkCheckinDrawer: PropTypes.func.isRequired,
    bulkData: PropTypes.shape({
        running: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired,
    newCheckins: PropTypes.arrayOf(String).isRequired,
    displayNewPatientNotification: PropTypes.bool.isRequired,
    updatedCheckinPatient: patientPropType.isRequired,
    displayUpdatePatientNotification: PropTypes.bool.isRequired,
    allowPrint: PropTypes.bool.isRequired,
};

CheckedInRoster.defaultProps = {
    updateError: null,
};

export default CheckedInRoster;
