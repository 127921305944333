import get from 'lodash/get';

function beforeRequest(actionContext) {
    actionContext.dispatch('GET_CHECKED_IN_PATIENTS_START');
}

function onSuccess(actionContext, { response }) {
    actionContext.dispatch('GET_CHECKED_IN_PATIENTS_SUCCESS', response.data);
}

function onFailed(actionContext) {
    actionContext.dispatch('GET_CHECKED_IN_PATIENTS_FAILED');
}

export default config => ({
    serviceName: 'getCheckedInPatients',
    authenticate: true,
    config: {
        method: 'GET',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkIn.searchQuery'),
        // baseUrl: 'http://localhost:3000'
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
