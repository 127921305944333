export default function runSearchQueryServiceMock({ resolve }) {
    setTimeout(() => {
        resolve([
            {
                mrn: 'one',
                firstName: 'Chris',
                middleName: null,
                lastName: 'Micle',
                dob: '2000-01-01T00:00:00.000Z',
                gender: 'male',
                address1: '100 court',
                address2: '',
                city: 'Raleigh',
                state: 'NC',
                zip: '27603',
                countryCode: '+1',
                phone: '1111111111',
            },
            {
                mrn: 'two',
                firstName: 'Joe',
                middleName: null,
                lastName: 'Gregory',
                dob: '2001-01-01T00:00:00.000Z',
                gender: 'male',
                address1: '200 Lane',
                address2: '',
                city: 'Cary',
                state: 'NC',
                zip: '111111',
                countryCode: '+1',
                phone: '2222222222',
            },
            {
                mrn: 'three',
                firstName: 'Dave',
                middleName: null,
                lastName: 'Byers',
                dob: '2002-01-01T00:00:00.000Z',
                gender: 'male',
                address1: '200 Ave',
                address2: '',
                city: 'Baltimore',
                state: 'MD',
                zip: '111111',
                countryCode: '+1',
                phone: '3333333333',
            },
        ]);
    }, 1000);
}
