import merge from 'lodash/merge';

import applicationConfiguration from './application-configuration';
import applicationLocalization from './application-localization';

export default applicationContext => {
    const defaults = {
        applicationId: 'ec',
        applicationName: 'Emergency Census',
        applicationConfiguration,
        applicationLocalization,
    };

    return merge(defaults, applicationContext);
};
