import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Spinner from '@audacious/components/components/Spinner';
import { withRouter } from 'react-router-dom';

class Intermediate extends React.Component {
    componentDidMount() {
        const { step, history } = this.props;
        if (isEqual(step, 'error')) {
            history.push('/error');
        } else {
            history.push('/home');
        }
    }

    render() {
        return (
            <div>
                <Spinner variant="overlay" spaceFromTop="sm" />
            </div>
        );
    }
}

Intermediate.propTypes = {
    step: PropTypes.bool.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
};

Intermediate.defaultProps = {};

const BoundComponent = connectToStores(
    Intermediate,
    ['CheckInWorkflowStore'],
    context => {
        const submittedCheckInsStore = context.getStore('CheckInWorkflowStore');
        const step = submittedCheckInsStore.getWorkflowStep();

        return {
            step,
        };
    },
);

export default withRouter(applyFluxibleContext(BoundComponent));
