import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import isNil from 'lodash/isNil';
import formatPatient from '../common/util/format-patient';

const initialState = {
    patients: [],
    loading: false,
    newPatients: [],
};

class CheckedInRosterStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    retrievePatientsStart() {
        this.setState({
            loading: true,
        });
    }

    retrievePatientsFinish([error, data = null]) {
        const { checkOuts } = data;

        const newState = {
            loading: false,
            error,
        };

        if (isNil(error)) {
            newState.patients = map(checkOuts, patient => ({
                id: patient.mrn,
                patient,
                formatted: formatPatient(patient),
            }));
        }

        this.setState(newState);
    }

    checkOutPatientsStart() {
        this.setState({
            newPatients: {},
            error: null,
            loading: true,
        });
    }

    checkOutPatientsFinish([error, patients]) {
        this.setState({
            newPatients: reduce(
                patients,
                (acc, patient) => {
                    acc[patient.mrn] = true;

                    return acc;
                },
                {},
            ),
            error,
            loading: false,
        });
    }

    clearNewPatients() {
        this.setState({
            newPatients: {},
            error: null,
            loading: false,
        });
    }

    isLoading() {
        return this.state.isLoading;
    }

    getPatients() {
        return this.state.patients;
    }

    getNewPatients() {
        return this.state.newPatients;
    }
}

CheckedInRosterStore.storeName = 'CheckedOutRosterStore';
CheckedInRosterStore.handlers = {
    RETRIEVE_CHECKED_OUT_PATIENTS_START: 'retrievePatientsStart',
    RETRIEVE_CHECKED_OUT_PATIENTS_FINISH: 'retrievePatientsFinish',
    CHECK_OUT_PATIENTS_START: 'checkOutPatientsStart',
    CHECK_OUT_PATIENTS_FINISH: 'checkOutPatientsFinish',
    CHECK_OUT_CLEAR_NEW_PATIENTS: 'clearNewPatients',
};

export default CheckedInRosterStore;
