import React from 'react';
import PropTypes from 'prop-types';
import { connectToStores } from 'fluxible-addons-react';
import formatDate from 'date-fns/format';
import { Row, Column } from '@audacious/components/components/Grid';
import TextInput from '@audacious/components/components/TextInput';
import Button from '@audacious/components/components/Button';
import Chip from '@audacious/components/components/Chip';
import { SimpleTable } from '@audacious/components/components/Table';
import {
    PageNavigation,
    RecordsPerPageSelector,
} from '@audacious/components/components/Pagination';
import IconButton from '@audacious/components/components/IconButton';
import RemovableChip from '@audacious/components/components/RemovableChip';
import Spinner from '@audacious/components/components/Spinner';
import { Text } from '@audacious/components/components/Typography';
import TableMessage from '@audacious/components/components/TableMessage';
import Tooltip from '@audacious/components/components/Tooltip';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { faMagnifyingGlass } from '@audacious/icons/regular/faMagnifyingGlass';
import { faSquareSliders } from '@audacious/icons/regular/faSquareSliders';
import { faPrint } from '@audacious/icons/solid/faPrint';
import { faArrowDownToLine } from '@audacious/icons/regular/faArrowDownToLine';
import capitalize from 'lodash/capitalize';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import isEqual from 'lodash/isEqual';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import patientPropType from '../patient-prop-type';
import CheckoutFilterDrawer from './checkout-filter-drawer';
import BulkCheckinDrawer from '../checked-in-roster/bulk-checkin-drawer';
import filterByDateRange from '../../../../common/util/filter-date-range';
import tableItemFilter from '../../../../common/util/table-item-filter';
import exportPatientsCsv from '../../../../common/util/export-patients-csv';
import printPatients from '../../../../common/util/print-patients';
import './checkout-filter-drawer.scss';

const checkoutTypeColor = {};
checkoutTypeColor.Home = 'navy';
checkoutTypeColor.Deceased = 'orange';
checkoutTypeColor.Other = 'purple';

class CheckedOutRoster extends React.Component {
    constructor(props) {
        super(props);

        this.patientListToPrintOrExport = [];

        this.state = {
            currentPage: 1,
            recordsPerPage: 50,
            filtersOpen: false,
            checkinStartDate: null,
            checkinEndDate: null,
            checkoutStartDate: null,
            checkoutEndDate: null,
            resetFilters: false,
            destination: null,
            searchValue: '',
        };

        this.handleChangeRecordsPerPage = this.handleChangeRecordsPerPage.bind(
            this,
        );
        this.handleGotoPage = this.handleGotoPage.bind(this);
        this.renderCheckOutDestination = this.renderCheckOutDestination.bind(
            this,
        );

        this.handleOpenCheckoutFilterDrawer = this.handleOpenCheckoutFilterDrawer.bind(
            this,
        );
        this.handleCloseCheckoutFilterDrawer = this.handleCloseCheckoutFilterDrawer.bind(
            this,
        );
        this.handleDateFiltering = this.handleDateFiltering.bind(this);
        this.resetFilters = this.resetFilters.bind(this);
        this.orchestrateFilters = this.orchestrateFilters.bind(this);
        this.filterByDestination = this.filterByDestination.bind(this);
        this.resetCheckinFilters = this.resetCheckinFilters.bind(this);
        this.resetDestinationFilters = this.resetDestinationFilters.bind(this);
        this.handleResetCheckoutFilters = this.handleResetCheckoutFilters.bind(
            this,
        );
        this.handleSortChange = this.handleSortChange.bind(this);
        this.handleFilterChange = this.handleFilterChange.bind(this);
        this.handleSearchInputChange = this.handleSearchInputChange.bind(this);
        this.handleDownloadClick = this.handleDownloadClick.bind(this);
        this.handlePrintClick = this.handlePrintClick.bind(this);

        this.COLUMNS = [
            {
                headerBody: 'Last Name',
                cellValuePath: ['patient.lastName', 'patient.mrn'],
                cellFilterPath: 'patient.lastName',
                sortable: true,
                width: '180px',
                renderCell: ([lastName, mrn]) => {
                    const { newPatients } = this.props;

                    const name = <Text weight="semi-bold">{lastName}</Text>;

                    if (newPatients[mrn] !== true) {
                        return name;
                    }

                    return (
                        <span>
                            {name}
                            <Chip
                                className="new-checkin-chip"
                                color="success"
                                size="sm"
                            >
                                New
                            </Chip>
                        </span>
                    );
                },
            },
            {
                headerBody: 'First Name',
                cellValuePath: 'patient.firstName',
                sortable: true,
                width: '150px',
            },
            {
                headerBody: 'DOB',
                cellValuePath: 'formatted.dobString',
                width: '100px',
            },
            {
                headerBody: 'Gender',
                cellValuePath: 'formatted.gender',
                width: '100px',
                filterable: false,
            },
            {
                headerBody: 'Address',
                cellValuePath: 'formatted.fullAddress',
                width: '155px',
            },
            {
                headerBody: 'Phone',
                cellValuePath: 'formatted.phone',
                width: '175px',
            },
            {
                headerBody: 'Check-In Date',
                cellValuePath: 'formatted.checkInDate',
                cellSortPath: 'patient.checkinTime',
                width: '155px',
                sortable: true,
            },
            {
                headerBody: 'Check-Out Date',
                cellValuePath: 'formatted.checkOutDate',
                cellSortPath: 'patient.checkoutTime',
                width: '170px',
                sortable: true,
            },
            {
                headerBody: 'Check-Out Destination',
                cellValuePath: 'patient.checkoutType',
                cellFilterPath: [
                    'patient.checkoutType',
                    'formatted.dateOfDeath',
                    'patient.checkoutLocation',
                ],
                width: '230px',
                sortable: true,
                renderCell: this.renderCheckOutDestination,
            },
        ];
    }

    handleChangeRecordsPerPage(recordsPerPage, page) {
        this.setState({ recordsPerPage, currentPage: page });
    }

    handleDateFiltering(
        checkinStartDate,
        checkinEndDate,
        destination,
        checkoutStartDate,
        checkoutEndDate,
    ) {
        this.setState({
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
            checkoutEndDate,
            filtersOpen: false,
            destination,
            currentPage: 1,
        });
    }

    handleGotoPage(page) {
        this.setState({ currentPage: page });
    }

    handleOpenCheckoutFilterDrawer() {
        this.setState({ filtersOpen: true, resetFilters: false });
    }

    handleCloseCheckoutFilterDrawer() {
        this.setState({ filtersOpen: false });
    }

    handleSortChange() {
        this.setState({ currentPage: 1 });
    }

    handleFilterChange(list) {
        this.patientListToPrintOrExport = list;
    }

    handleSearchInputChange(searchValue) {
        this.setState({
            searchValue,
            currentPage: 1,
        });
    }

    handleDownloadClick() {
        const { userName, facilityName, locationName } = this.props;

        const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        exportPatientsCsv({
            patients: this.patientListToPrintOrExport,
            userName,
            facilityName,
            locationName,
            date,
            eventType: 'checked-out',
        });
    }

    handlePrintClick() {
        const { userName, facilityName, locationName } = this.props;

        const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        printPatients({
            patients: this.patientListToPrintOrExport,
            userName,
            facilityName,
            locationName,
            date,
            eventType: 'checked-out',
        });
    }

    handleResetCheckoutFilters() {
        const { checkinStartDate, checkinEndDate, destination } = this.state;
        if (!checkinStartDate && !checkinEndDate && !destination) {
            this.setState({
                resetFilters: true,
                checkoutStartDate: null,
                checkoutEndDate: null,
            });
        }
        this.setState({
            checkoutStartDate: null,
            checkoutEndDate: null,
        });
    }

    resetFilters() {
        this.setState({
            resetFilters: true,
            checkinStartDate: null,
            checkinEndDate: null,
            destination: null,
        });
    }

    resetCheckinFilters() {
        const { checkoutStartDate, checkoutEndDate, destination } = this.state;
        if (!checkoutStartDate && !checkoutEndDate && !destination) {
            this.setState({
                resetFilters: true,
                checkinStartDate: null,
                checkinEndDate: null,
            });
        } else {
            this.setState({
                checkinStartDate: null,
                checkinEndDate: null,
            });
        }
    }

    orchestrateFilters(
        checkinStartDate,
        checkinEndDate,
        checkoutStartDate,
        checkoutEndDate,
        destination,
    ) {
        const { patients } = this.props;
        let filteredPatients = filterByDateRange(
            checkinStartDate,
            checkinEndDate,
            patients,
            'patient.checkinTime',
        );
        filteredPatients = this.filterByDestination(
            destination,
            filteredPatients,
        );
        filteredPatients = filterByDateRange(
            checkoutStartDate,
            checkoutEndDate,
            filteredPatients,
            'patient.checkoutTime',
        );
        return filteredPatients;
    }

    /* eslint-disable-next-line class-methods-use-this */
    filterByDestination(destination, filteredPatients) {
        if (isNil(destination)) {
            return filteredPatients;
        }
        const destinationFilteredPatients = [];
        forEach(filteredPatients, patient => {
            if (
                isEqual(
                    toUpper(destination),
                    toUpper(patient.patient.checkoutType),
                )
            ) {
                destinationFilteredPatients.push(patient);
            }
        });
        return destinationFilteredPatients;
    }

    resetDestinationFilters() {
        const {
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
            checkoutEndDate,
        } = this.state;
        if (
            !checkinStartDate &&
            !checkinEndDate &&
            !checkoutStartDate &&
            !checkoutEndDate
        ) {
            this.setState({
                resetFilters: true,
                destination: null,
            });
        }
        this.setState({
            destination: null,
        });
    }

    // eslint-disable-next-line class-methods-use-this
    renderCheckOutDestination(cellValue, checkedOutPatient) {
        const checkOutType = capitalize(checkedOutPatient.patient.checkoutType);
        const { dateOfDeath } = checkedOutPatient.formatted;

        const renderDateOfDeath = dateOfDeath ? (
            <span> ({dateOfDeath})</span>
        ) : null;

        return (
            <div>
                <div key={checkedOutPatient.mrn}>
                    <Chip color={checkoutTypeColor[checkOutType]} size="sm">
                        {checkOutType}
                    </Chip>
                    {renderDateOfDeath}
                </div>
                <div>{checkedOutPatient.patient.checkoutLocation}</div>
            </div>
        );
    }

    render() {
        const {
            currentPage,
            recordsPerPage,
            filtersOpen,
            checkinStartDate,
            checkinEndDate,
            checkoutStartDate,
            checkoutEndDate,
            resetFilters,
            destination,
            searchValue,
        } = this.state;
        const {
            patients,
            isLoading,
            showBulkCheckinDrawer,
            cancelBulkCheckinDrawer,
            bulkData,
            allowPrint,
        } = this.props;

        let noPatientsMessage =
            !patients || patients.length === 0
                ? 'No one is checked out at this time'
                : null;

        let filteredPatients = resetFilters
            ? patients
            : this.orchestrateFilters(
                  checkinStartDate,
                  checkinEndDate,
                  checkoutStartDate,
                  checkoutEndDate,
                  destination,
              );
        this.patientListToPrintOrExport = filteredPatients;

        const noPatients = !filteredPatients || filteredPatients.length === 0;

        if (noPatients && !noPatientsMessage) {
            noPatientsMessage = 'No record matched your Filter(s).';
        }

        filteredPatients = tableItemFilter(
            filteredPatients,
            this.COLUMNS,
            searchValue,
        );

        if (
            (!filteredPatients || filteredPatients.length === 0) &&
            !noPatientsMessage
        ) {
            noPatientsMessage = 'No record matched your search.';
        }

        const exportButtons = allowPrint ? (
            <>
                <Tooltip
                    anchor={IconButton}
                    anchorProps={{
                        id: 'checked-out-roster-export-button',
                        icon: faArrowDownToLine,
                        size: 'sm',
                        'aria-label': 'Export CSV',
                        disabled: noPatients,
                    }}
                    onAnchorClick={this.handleDownloadClick}
                    disabled={noPatients}
                >
                    Download
                </Tooltip>
                <Tooltip
                    anchor={IconButton}
                    anchorProps={{
                        id: 'checked-out-roster-print-button',
                        icon: faPrint,
                        size: 'sm',
                        'aria-label': 'Print',
                        disabled: noPatients,
                    }}
                    onAnchorClick={this.handlePrintClick}
                    disabled={noPatients}
                >
                    Print
                </Tooltip>
            </>
        ) : null;

        return (
            <div id="checked-out-roster" className="roster">
                {isLoading ? (
                    <PageContainerGroup>
                        <Spinner
                            id="checked-out-roster-spinner"
                            variant="overlay"
                            spaceFromTop="sm"
                        />
                    </PageContainerGroup>
                ) : null}
                <PageContainerGroup>
                    <Row className="roster-header" gutter="24" enableAfter>
                        <Column width="fill">
                            <Text size="xl" weight="bold">
                                Checked-Out Roster
                            </Text>
                        </Column>
                        <Column width="static:268">
                            <TextInput
                                id="checked-out-roster-search-input"
                                size="sm"
                                placeholder="Search"
                                leftIcon={{ icon: faMagnifyingGlass }}
                                disabled={noPatients}
                                value={searchValue}
                                onChange={this.handleSearchInputChange}
                            />
                        </Column>
                    </Row>
                    <Row gutter="24" enableAfter>
                        <Column width="fill">
                            <Button
                                id="checked-out-roster-filters-button"
                                variant="outline"
                                color="secondary"
                                size="sm"
                                leftIcon={faSquareSliders}
                                disabled={
                                    noPatients &&
                                    !checkinStartDate &&
                                    !checkoutStartDate &&
                                    !destination
                                }
                                onClick={() =>
                                    this.handleOpenCheckoutFilterDrawer()
                                }
                            >
                                Filters
                            </Button>
                            {destination ? (
                                <RemovableChip
                                    id="destination-filter-remove"
                                    color="blue"
                                    onClear={this.resetDestinationFilters}
                                    shade="lighter"
                                    size="lg"
                                    className="ec-chip"
                                    variant="pill"
                                >
                                    <span className="ec-checkin-chip-text">
                                        {destination}
                                    </span>
                                </RemovableChip>
                            ) : null}
                            {checkinStartDate ? (
                                <RemovableChip
                                    id="checkin-filter-remove"
                                    color="orange"
                                    onClear={this.resetCheckinFilters}
                                    shade="lighter"
                                    size="lg"
                                    className="ec-chip"
                                    variant="pill"
                                >
                                    <span className="ec-checkin-chip-label">
                                        Check-In Date
                                    </span>{' '}
                                    {checkinEndDate ? (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkinStartDate,
                                                'MM/dd/yyyy',
                                            )}{' '}
                                            -
                                            {formatDate(
                                                checkinEndDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    ) : (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkinStartDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    )}
                                </RemovableChip>
                            ) : null}
                            {checkoutStartDate ? (
                                <RemovableChip
                                    id="checkout-filter-remove"
                                    color="orange"
                                    onClear={this.handleResetCheckoutFilters}
                                    shade="lighter"
                                    size="lg"
                                    className="ec-chip"
                                    variant="pill"
                                >
                                    <span className="ec-checkin-chip-label">
                                        Check-Out Date
                                    </span>{' '}
                                    {checkoutEndDate ? (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkoutStartDate,
                                                'MM/dd/yyyy',
                                            )}{' '}
                                            -
                                            {formatDate(
                                                checkoutEndDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    ) : (
                                        <span className="ec-checkin-chip-text">
                                            {formatDate(
                                                checkoutStartDate,
                                                'MM/dd/yyyy',
                                            )}
                                        </span>
                                    )}
                                </RemovableChip>
                            ) : null}
                        </Column>
                        <Column width="content">
                            <RecordsPerPageSelector
                                id="checked-out-roster-records-per-page"
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                recordsPerPageOptions={[20, 50, 100]}
                                onChangeRecordsPerPage={
                                    this.handleChangeRecordsPerPage
                                }
                                tooltipText="Total Number of Records"
                            />
                            <PageNavigation
                                id="checked-out-roster-top-page-nav"
                                firstAndLastNav
                                size="sm"
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                onGotoPage={this.handleGotoPage}
                            />
                            {exportButtons}
                        </Column>
                    </Row>
                </PageContainerGroup>
                <SimpleTable
                    id="checked-out-roster-table"
                    items={filteredPatients}
                    columns={this.COLUMNS}
                    minWidth={1200}
                    alwaysShowHeaders
                    currentPage={currentPage}
                    recordsPerPage={recordsPerPage}
                    stickyColumns={1}
                    initialSortPath="patient.checkoutTime"
                    initialSortDescending
                    onSortChange={this.handleSortChange}
                    onFilteredItemsChange={this.handleFilterChange}
                />
                {noPatientsMessage ? (
                    <PageContainerGroup>
                        <TableMessage header={noPatientsMessage} />
                    </PageContainerGroup>
                ) : null}
                <PageContainerGroup className="footer-group">
                    <Row gutter="24">
                        <Column width="fill" />
                        <Column width="content">
                            <PageNavigation
                                id="checked-out-roster-bottom-page-nav"
                                firstAndLastNav
                                jumpToPageNav
                                numRecords={filteredPatients.length}
                                currentPage={currentPage}
                                recordsPerPage={recordsPerPage}
                                onGotoPage={this.handleGotoPage}
                            />
                        </Column>
                        <Column width="fill" />
                    </Row>
                </PageContainerGroup>
                <CheckoutFilterDrawer
                    open={filtersOpen}
                    onCancel={() => this.handleCloseCheckoutFilterDrawer()}
                    onConfirm={this.handleDateFiltering}
                    resetFilters={resetFilters}
                    checkinStartDateInUse={checkinStartDate}
                    checkinEndDateInUse={checkinEndDate}
                    checkoutStartDateInUse={checkoutStartDate}
                    checkoutEndDateInUse={checkoutEndDate}
                    destinationInUse={destination}
                />
                <BulkCheckinDrawer
                    open={showBulkCheckinDrawer}
                    onCancel={() => cancelBulkCheckinDrawer()}
                    onConfirm={this.handleDateFiltering}
                    bulkData={bulkData}
                />
            </div>
        );
    }
}

CheckedOutRoster.propTypes = {
    userName: PropTypes.string.isRequired,
    facilityName: PropTypes.string.isRequired,
    locationName: PropTypes.string.isRequired,
    patients: PropTypes.arrayOf(patientPropType).isRequired,
    isLoading: PropTypes.bool.isRequired,
    showBulkCheckinDrawer: PropTypes.bool.isRequired,
    cancelBulkCheckinDrawer: PropTypes.func.isRequired,
    bulkData: PropTypes.shape({
        running: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired,
    allowPrint: PropTypes.bool.isRequired,
    newPatients: PropTypes.objectOf(PropTypes.bool).isRequired,
};

export default connectToStores(
    CheckedOutRoster,
    ['CheckedOutRosterStore'],
    context => {
        const checkedOutRosterStore = context.getStore('CheckedOutRosterStore');

        return {
            patients: checkedOutRosterStore.getPatients(),
            isLoading: checkedOutRosterStore.isLoading(),
            newPatients: checkedOutRosterStore.getNewPatients(),
        };
    },
);
