import isNil from 'lodash/isNil';

export default function scrollTopTop(startElement) {
    let targetElement = startElement;

    while (!isNil(targetElement)) {
        if (targetElement.scrollTop !== 0) {
            targetElement.scrollTop = 0;
        }

        targetElement = targetElement.parentNode;
    }
}
