import merge from 'lodash/merge';
import createCheckIns from './create-check-in-service.mock';
import createCheckOuts from './create-check-out-service.mock';
import runSearchQuery from './run-search-query.mock';

const DEFAULT_MOCK_SERVICES = {
    createCheckIns,
    createCheckOuts,
    runSearchQuery,
};

/* These are the default mock service configurations.
 * enabled - When set to true will enable ALL mocks.
 * include - a list of service names which will ALWAYS be mocked, even if enabled is false.
 * exclude - a list of service names which will NEVER be mocked, even is enabled is true.
 * immediate - When set to false, ALL service request will prompt the user one how to proceed. Meant for DEBUG purposes. This does not require mocks to be enabled.
 */
export default ({
    enabled = false,
    include = [],
    exclude = [],
    immediate = true,
    services = {},
}) => ({
    enabled,
    include,
    exclude,
    immediate,
    services: merge(DEFAULT_MOCK_SERVICES, services),
});
