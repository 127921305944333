const namePattern = /^(?!\s)(?![\s\S]*\s$)^[A-Za-z0-9\s\-']*$/;
const zipCodePattern = /^([0-9]{5})(-[0-9]{4})?$/;
const countryCodePattern = /^\+\d{1,4}$/;
const phonePattern = /^\d{3}-\d{3}-\d{4}$/;
const socialPattern = /(^\d{3}-\d{2}-\d{4}$)|(^\d{3}\d{2}\d{4}$)|(^\d{4}$)/;
const phonePatternJustNumbers = /^\d{10}$/;
const basicPattern = /^[^&~^\\]*$/;

export {
    namePattern,
    zipCodePattern,
    countryCodePattern,
    phonePattern,
    phonePatternJustNumbers,
    basicPattern,
    socialPattern,
};
