/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes from 'prop-types';
import { Row, Column } from '@audacious/components/components/Grid';
import Data, {
    DataTextProperty,
    DataSelectProperty,
    DataMaskProperty,
    DataDateProperty,
} from '@audacious/components/components/Data';
import isEmpty from 'lodash/isEmpty';
import getDateParts from '@audacious/web-common/utilities/getDateParts';
import get from 'lodash/get';
import toLower from 'lodash/toLower';
import zipCodeMask from '../../../../common/zipcode-mask';
import socialMask from '../../../../common/social-mask';
import validateDob from '../../../../common/validate-dob';
import validateGender from '../../../../common/validate-gender';
import validateState from '../../../../common/validate-state';
import patientPropType from '../patient-prop-type';
import GenderOptions from '../../../../common/gender-options';
import StatesOptions from '../../../../common/usa-states-options';
import { MIN_DATE, MAX_DATE } from '../../../../constants/dates';
import { updateReviewCheckIns } from '../../../../actions/check-in-actions';

import {
    basicPattern,
    namePattern,
    countryCodePattern,
    phonePattern,
    zipCodePattern,
    socialPattern,
} from '../../../../constants/reg-ex-patterns';
import {
    INVALID_VALUE_MSG,
    INVALID_CHARACTERS_MSG,
} from '../../../../constants/error-messages';

function handleExecuteStart(_, results) {
    return isEmpty(results);
}

class EditForm extends Component {
    constructor(props) {
        super(props);

        this.handleExecute = this.handleExecute.bind(this);
    }

    handleExecute(data) {
        const {
            facilityId,
            isCheckedIn,
            onEditPatientSave: handleEditPatientSave,
            fluxibleContext: {
                executeAction,
                service: { updateCheckIn },
            },
        } = this.props;
        const { patient, formatted } = data;
        const dobDate = get(formatted, 'dobDate', null);
        const gender = get(formatted, 'gender', null);

        if (isCheckedIn !== true) {
            // get the updated dob value from the formatted object.
            const updatedCheckInPatient = data;

            updatedCheckInPatient.patient.gender = gender;
            updatedCheckInPatient.patient.dob = dobDate;

            // Remove the validation issues if any exists from previous bulk checkin process
            updatedCheckInPatient.issues = [];

            handleEditPatientSave();
            executeAction(updateReviewCheckIns, updatedCheckInPatient);
        } else {
            patient.dob = getDateParts(dobDate);
            patient.gender = gender;

            const patients = [patient];

            updateCheckIn({
                options: { facilityId },
                data: { patients },
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    renderFormContents() {
        return (
            // <Container gutter="16">
            <>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="firstName"
                            path="patient.firstName"
                            label="First Name*"
                            required={[true, INVALID_VALUE_MSG]}
                            minLength={[2, INVALID_VALUE_MSG]}
                            maxLength={[50, INVALID_VALUE_MSG]}
                            pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="middleName"
                            path="patient.middleName"
                            label="Middle Name"
                            maxLength={[50, INVALID_VALUE_MSG]}
                            pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="lastName"
                            path="patient.lastName"
                            label="Last Name*"
                            required={[true, INVALID_VALUE_MSG]}
                            minLength={[2, INVALID_VALUE_MSG]}
                            maxLength={[50, INVALID_VALUE_MSG]}
                            pattern={[namePattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataDateProperty
                            id="dateOfBirth"
                            label="Date of Birth*"
                            path="formatted.dobDate"
                            required={[true, INVALID_VALUE_MSG]}
                            fromDate={MIN_DATE}
                            toDate={MAX_DATE}
                            attributes={{
                                validDate: {
                                    enabled: true,
                                    onValidate: validateDob,
                                },
                            }}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataSelectProperty
                            id="gender"
                            label="Gender*"
                            // aria-label="Gender"
                            path="formatted.gender"
                            options={GenderOptions}
                            required={[true, INVALID_VALUE_MSG]}
                            attributes={{
                                validGender: {
                                    enabled: true,
                                    onValidate: validateGender,
                                },
                            }}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="address1"
                            path="patient.address1"
                            label="Address Line 1"
                            minLength={[2, INVALID_VALUE_MSG]}
                            maxLength={[255, INVALID_VALUE_MSG]}
                            pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="address2"
                            path="patient.address2"
                            label="Address Line 2"
                            minLength={[2, INVALID_VALUE_MSG]}
                            maxLength={[255, INVALID_VALUE_MSG]}
                            pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataTextProperty
                            id="city"
                            path="patient.city"
                            label="City"
                            minLength={[2, INVALID_VALUE_MSG]}
                            maxLength={[35, INVALID_VALUE_MSG]}
                            pattern={[basicPattern, INVALID_CHARACTERS_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataSelectProperty
                            id="state"
                            label="State"
                            // aria-label="Gender"
                            path="patient.state"
                            options={StatesOptions}
                            attributes={{
                                validGender: {
                                    enabled: true,
                                    onValidate: validateState,
                                },
                            }}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataMaskProperty
                            id="zip"
                            path="patient.zip"
                            label="Zip Code*"
                            mask={zipCodeMask}
                            required={[true, INVALID_VALUE_MSG]}
                            pattern={[zipCodePattern, INVALID_VALUE_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="4">
                        <DataMaskProperty
                            id="countryCode"
                            label="Country Code*"
                            path="patient.countryCode"
                            pattern={[countryCodePattern, INVALID_VALUE_MSG]}
                            mask={['+', /[1-9]/, /\d/, /\d/, /\d/]}
                            required={[true, INVALID_VALUE_MSG]}
                        />
                    </Column>
                    <Column width="8">
                        <DataMaskProperty
                            id="phone"
                            label="Area and Phone Number*"
                            aria-label="Area and Phone Number"
                            required={[true, INVALID_VALUE_MSG]}
                            path="patient.phone"
                            mask={[
                                /[1-9]/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                '-',
                                /\d/,
                                /\d/,
                                /\d/,
                                /\d/,
                            ]}
                            pattern={[phonePattern, INVALID_VALUE_MSG]}
                        />
                    </Column>
                </Row>
                <Row gutter="16">
                    <Column width="12">
                        <DataMaskProperty
                            id="ssn"
                            path="patient.ssn"
                            label="Social Security Number"
                            mask={socialMask}
                            pattern={[socialPattern, INVALID_VALUE_MSG]}
                            secure
                        />
                    </Column>
                </Row>
            </>
        );
    }

    render() {
        const { initialData, dataRef } = this.props;

        if (toLower(initialData.formatted.dob) === 'invalid date') {
            initialData.formatted.dob = null;
        }

        return (
            <Data
                ref={dataRef}
                baseValue={initialData}
                onExecuteStart={handleExecuteStart}
                onExecute={this.handleExecute}
                validateOnExecute
                validateOnBlur
            >
                {this.renderFormContents()}
            </Data>
        );
    }
}

EditForm.propTypes = {
    initialData: PropTypes.arrayOf(patientPropType).isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    dataRef: PropTypes.object.isRequired,
    facilityId: PropTypes.string.isRequired,
    isCheckedIn: PropTypes.bool.isRequired,
    onEditPatientSave: PropTypes.func,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
        service: {
            updateCheckIn: PropTypes.func,
        },
    }).isRequired,
};

EditForm.defaultProps = {
    onEditPatientSave: null,
};

export default applyFluxibleContext(EditForm);
