import isNil from 'lodash/isNil';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import { columnDefinitions, definitionOrder } from './column-definitions';

function buildPatientCSVString(patient, groupId, columnPostfix) {
    let csvString = '';

    const patientData = !isNil(groupId) ? patient[groupId] : patient;

    forEach(definitionOrder, (definitionId, index) => {
        const definition = columnDefinitions[definitionId];

        csvString += get(patientData, definition.path, '');

        if (index < definitionOrder.length - 1) {
            csvString += ',';
        }
    });

    if (!isNil(columnPostfix)) {
        csvString += ',';

        csvString += columnPostfix(patient, groupId);
    }

    return csvString;
}

export function buildPatientsCSV(
    patients,
    groupId,
    headerPrefix,
    columnPostfix,
) {
    let csvString = '';

    forEach(definitionOrder, (definitionId, index) => {
        const definition = columnDefinitions[definitionId];

        csvString += definition.label;

        if (index < definitionOrder.length - 1) {
            csvString += ',';
        }
    });

    if (!isNil(headerPrefix)) {
        csvString += ',';

        csvString += headerPrefix();
    }

    csvString += '\n';

    forEach(patients, (patient, patientIndex) => {
        csvString += buildPatientCSVString(patient, groupId, columnPostfix);

        if (patientIndex < patients.length - 1) {
            csvString += '\n';
        }
    });

    return csvString;
}

export function downloadCSV(csvString, fileName) {
    const link = document.createElement('a');
    link.href = `data:text/csv;charset=UTF-8,${encodeURIComponent(csvString)}`;
    link.download = `${fileName}.csv`;
    link.click();
}
