import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import startOfTomorrow from 'date-fns/startOfTomorrow';
import Dialog from '@audacious/components/components/Dialog';
import { SubHeading } from '@audacious/components/components/Typography';
import Document, {
    DocumentHeader,
    DocumentBody,
    DocumentFooter,
} from '@audacious/components/components/Document';
import Button from '@audacious/components/components/Button';
import { Row, Column } from '@audacious/components/components/Grid';
import IconButton from '@audacious/components/components/IconButton';
import { faPlus } from '@audacious/icons/regular/faPlus';
import { faXmark } from '@audacious/icons/regular/faXmark';
import DateInput from '@audacious/components/components/DateInput';

import {
    INVALID_VALUE_MSG,
    // INVALID_CHARACTERS_MSG,
} from '../../../../constants/error-messages';
import { MIN_DATE, MAX_DATE } from '../../../../constants/dates';
import './checkin-filter-drawer.scss';

function isValidDate(date) {
    return date instanceof Date && !Number.isNaN(Number(date));
}

class CheckinFilterDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            checkinStartDate: null,
            checkinEndDate: null,
            checkinStartDatevalid: true,
            checkinEndDatevalid: true,
            checkinStartDateinvalidMessages: [],
            checkinEndDateinvalidMessages: [],
            showSecondDate: false,
        };

        this.handleExecutecheckinStartDate = this.handleExecutecheckinStartDate.bind(
            this,
        );
        this.handleExecutecheckinEndDate = this.handleExecutecheckinEndDate.bind(
            this,
        );
        this.addSecondDate = this.addSecondDate.bind(this);
        this.removeSecondDate = this.removeSecondDate.bind(this);
        this.handleValidations = this.handleValidations.bind(this);
        this.handleResetFilters = this.handleResetFilters.bind(this);
        this.handleOnCancel = this.handleOnCancel.bind(this);
    }

    handleValidations(checkinStartDate, checkinEndDate) {
        const tomorrow = startOfTomorrow();
        const checkinEndDatedate = new Date(checkinEndDate);
        const checkinStartDatedate = new Date(checkinStartDate);
        let checkinStartDateinvalidMessages = [];
        let checkinEndDateinvalidMessages = [];
        let checkinStartDatevalid = true;
        let checkinEndDatevalid = true;

        if (!isValidDate(checkinStartDatedate)) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Invalid date \n');
        }

        if (!isValidDate(checkinEndDatedate)) {
            checkinEndDatevalid = false;
            checkinEndDateinvalidMessages.push('Invalid date \n');
        }

        if (checkinEndDate && checkinStartDatedate > checkinEndDatedate) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push(
                'First date cannot be after second date \n',
            );
        }

        if (checkinEndDate && checkinEndDatedate >= tomorrow) {
            checkinEndDatevalid = false;
            checkinEndDateinvalidMessages.push('Future dates are invalid \n');
        }
        if (checkinStartDatedate >= tomorrow) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Future dates are invalid \n');
        }

        if (checkinEndDate && !checkinStartDate) {
            checkinStartDatevalid = false;
            checkinStartDateinvalidMessages.push('Required field \n');
        }

        if (checkinStartDatevalid) {
            checkinStartDateinvalidMessages = [];
        }

        if (checkinEndDatevalid) {
            checkinEndDateinvalidMessages = [];
        }

        this.setState({
            checkinStartDate,
            checkinEndDate,
            checkinStartDatevalid,
            checkinEndDatevalid,
            checkinStartDateinvalidMessages,
            checkinEndDateinvalidMessages,
        });
    }

    handleExecutecheckinStartDate(value) {
        const { checkinEndDate } = this.state;
        this.handleValidations(value, checkinEndDate);
    }

    handleExecutecheckinEndDate(value) {
        const { checkinStartDate } = this.state;
        this.handleValidations(checkinStartDate, value);
    }

    handleResetFilters() {
        this.setState({
            checkinStartDate: null,
            checkinEndDate: null,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
            checkinStartDatevalid: true,
            checkinStartDateinvalidMessages: [],
            showSecondDate: false,
        });
    }

    handleOnCancel() {
        const {
            onCancel,
            checkinStartDateInUse,
            checkinEndDateInUse,
        } = this.props;
        this.setState({
            checkinStartDate: checkinStartDateInUse,
            checkinEndDate: checkinEndDateInUse,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
            checkinStartDatevalid: true,
            checkinStartDateinvalidMessages: [],
            showSecondDate: false,
        });
        onCancel();
    }

    addSecondDate() {
        this.setState({ showSecondDate: true });
    }

    removeSecondDate() {
        const { checkinStartDate } = this.state;
        this.setState({
            showSecondDate: false,
            checkinEndDate: null,
            checkinEndDatevalid: true,
            checkinEndDateinvalidMessages: [],
        });
        this.handleValidations(checkinStartDate, null);
    }

    render() {
        const {
            open,
            onConfirm,
            resetFilters,
            checkinEndDateInUse,
        } = this.props;

        const {
            checkinStartDate,
            checkinEndDate,
            showSecondDate,
            checkinStartDatevalid,
            checkinEndDatevalid,
            checkinEndDateinvalidMessages,
            checkinStartDateinvalidMessages,
        } = this.state;

        if (resetFilters && checkinStartDate) {
            this.handleResetFilters();
        }

        const showSecondDateRender = showSecondDate || checkinEndDateInUse;

        const checkinStartDateWidth = showSecondDateRender ? '5' : '10';

        return (
            <Dialog
                open={open}
                size="sm"
                variant="drawer-right"
                className="event-details"
            >
                <Document size="sm">
                    <DocumentHeader>
                        <SubHeading level="4">Filters</SubHeading>
                    </DocumentHeader>
                    <DocumentBody>
                        <Row gutter="16">
                            <Column width={checkinStartDateWidth}>
                                <DateInput
                                    id="checkin-date-1"
                                    label="Check-In Date"
                                    path="checkinStartDate"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={
                                        this.handleExecutecheckinStartDate
                                    }
                                    value={checkinStartDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    invalid={!checkinStartDatevalid}
                                    messages={{
                                        invalid: checkinStartDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showSecondDateRender}>
                                <div className="ec-date-dash">-</div>
                            </Column>
                            <Column width="2" hidden={showSecondDateRender}>
                                <IconButton
                                    icon={faPlus}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.addSecondDate}
                                    hidden={showSecondDateRender}
                                />
                            </Column>
                            <Column width="5">
                                <DateInput
                                    id="checkin-date-2"
                                    label="&nbsp;"
                                    path="checkinEndDate"
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    validateOnBlur
                                    onChange={this.handleExecutecheckinEndDate}
                                    value={checkinEndDate}
                                    fromDate={MIN_DATE}
                                    toDate={new Date()}
                                    hidden={!showSecondDateRender}
                                    invalid={!checkinEndDatevalid}
                                    messages={{
                                        invalid: checkinEndDateinvalidMessages,
                                    }}
                                />
                            </Column>
                            <Column width="1" hidden={!showSecondDateRender}>
                                <IconButton
                                    icon={faXmark}
                                    size="md"
                                    className="ec-date-x"
                                    onClick={this.removeSecondDate}
                                    hidden={!showSecondDateRender}
                                />
                            </Column>
                        </Row>
                    </DocumentBody>
                    <DocumentFooter>
                        <Button
                            id="checkinFiltersDrawerDone"
                            type="submit"
                            color="primary"
                            variant="fill"
                            onClick={() =>
                                onConfirm(checkinStartDate, checkinEndDate)
                            }
                            disabled={
                                !checkinStartDate ||
                                !checkinStartDatevalid ||
                                !checkinEndDatevalid
                            }
                        >
                            Done
                        </Button>
                        <Button
                            color="primary"
                            variant="opaque"
                            id="checkinFiltersDrawerClose"
                            onClick={() => this.handleOnCancel()}
                            focusOnMount
                        >
                            Cancel
                        </Button>
                    </DocumentFooter>
                </Document>
            </Dialog>
        );
    }
}

CheckinFilterDrawer.propTypes = {
    open: PropTypes.bool.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    resetFilters: PropTypes.bool.isRequired,
    checkinStartDateInUse: PropTypes.string,
    checkinEndDateInUse: PropTypes.string,
};

CheckinFilterDrawer.defaultProps = {
    checkinStartDateInUse: null,
    checkinEndDateInUse: null,
};

export default CheckinFilterDrawer;
