import merge from 'lodash/merge';
import { services as facilityServices } from '@audacious/pulse-facility-selection';

import endpoints from '../configs/endpoints';
import checkInService from './create-check-in-service';
import checkOutService from './create-check-out-service';
import runSearchQuery from './run-search-query';
import retrieveEpraPreferences from './epra-preferences/retrieveEpraPreferences';
import getCheckedInPatients from './get-checked-in-patients';
import getCheckedOutPatients from './get-checked-out-patients';
import updateCheckIn from './update-check-in-patient';

const DEFAULT_CONFIG = {
    endpoints,
};

export default config => {
    const configuration = merge(DEFAULT_CONFIG, config); // eslint-disable-line

    return [
        checkInService(configuration),
        checkOutService(configuration),
        runSearchQuery(configuration),
        retrieveEpraPreferences(configuration),
        getCheckedInPatients(configuration),
        getCheckedOutPatients(configuration),
        updateCheckIn(configuration),
        ...facilityServices,
    ];
};
