const GenderOptions = [
    {
        text: 'Female',
        key: 'female',
    },
    {
        text: 'Male',
        key: 'male',
    },
    {
        text: 'Unknown',
        key: 'unknown',
    },
    {
        text: 'Other',
        key: 'other',
    },
];

export default GenderOptions;
