import PropTypes from 'prop-types';

const searchDataPropType = PropTypes.shape({
    query: {
        firstName: PropTypes.string,
        lastName: PropTypes.string,
    },
    results: PropTypes.arrayOf(
        PropTypes.shape({
            formatted: PropTypes.shape({
                name: PropTypes.string,
                address: PropTypes.string,
                dob: PropTypes.string,
                phone: PropTypes.string,
                gender: PropTypes.string,
            }),
        }),
    ),
    running: PropTypes.bool,
    error: PropTypes.string,
});

export default searchDataPropType;
