import PropTypes from 'prop-types';

const checkInPatientPropType = PropTypes.shape({
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    dob: PropTypes.shape({
        day: PropTypes.number,
        month: PropTypes.number,
        year: PropTypes.number,
    }),
    gender: PropTypes.string,
    address1: PropTypes.string,
    address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    countryCode: PropTypes.string,
    phone: PropTypes.string,
    needsMedicalAttention: PropTypes.bool,
});

const checkInData = PropTypes.shape({
    issues: PropTypes.arrayOf(
        PropTypes.shape({
            path: PropTypes.string,
            messages: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                }),
            ),
        }),
    ),
    patient: checkInPatientPropType,
    formatted: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.shape({
            address1: PropTypes.string,
            address2: PropTypes.string,
            city: PropTypes.string,
            state: PropTypes.string,
            zipCode: PropTypes.string,
        }),
        dob: PropTypes.string,
        phone: PropTypes.string,
        gender: PropTypes.string,
    }),
});

const checkInsPropType = PropTypes.arrayOf(checkInData);

export { checkInPatientPropType, checkInData, checkInsPropType };
