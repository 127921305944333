import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import isNil from 'lodash/isNil';
import lodashGet from 'lodash/get';
import mapValues from 'lodash/mapValues';
import { Row, Column } from '@audacious/components/components/Grid';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Button from '@audacious/components/components/Button';
import { Text } from '@audacious/components/components/Typography';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { SimpleTable, Cell } from '@audacious/components/components/Table';
import Data, {
    DataGroup,
    DataAccess,
    DataSelectProperty,
    DataTextProperty,
    DataDateProperty,
} from '@audacious/components/components/Data';
import {
    startReview,
    cancelDestination,
} from '../../../actions/check-out-actions';
import selectedPatientsPropType from './selected-patients-prop-type';
import {
    INVALID_VALUE_MSG,
    INVALID_CHARACTERS_MSG,
} from '../../../constants/error-messages';
import { MIN_DATE, MAX_DATE } from '../../../constants/dates';
import { basicPattern } from '../../../constants/reg-ex-patterns';
import { clearStores } from '../../../actions/home-actions';

import './check-out.scss';

const Destinations = {
    HOME: 'home',
    OTHER: 'other',
    DECEASED: 'deceased',
};

const DESTINATION_OPTIONS = [
    {
        text: 'Home',
        key: Destinations.HOME,
    },
    {
        text: 'Other',
        key: Destinations.OTHER,
    },
    {
        text: 'Deceased',
        key: Destinations.DECEASED,
    },
];

const columns = [
    {
        headerBody: 'Last Name',
        cellValuePath: 'patient.lastName',
        bold: true,
    },
    {
        headerBody: 'First Name',
        cellValuePath: 'patient.firstName',
    },
    {
        headerBody: 'DOB',
        cellValuePath: 'formatted.dobString',
    },
    {
        headerBody: 'Gender',
        cellValuePath: 'formatted.gender',
    },
    {
        headerBody: 'Check-Out Destination',
        cellValuePath: 'destination.type',
        width: '400px',
        renderCell: (cellValue, item, rowIndex) => (
            <DataSelectProperty
                id={`destination-${rowIndex}`}
                className="destination-select"
                aria-label="destination"
                width="md"
                options={DESTINATION_OPTIONS}
                path={`${item.id}.destination.type`}
                nullable={false}
                size="sm"
            />
        ),
    },
];

class CheckOutDestination extends React.Component {
    constructor(props) {
        super(props);

        this.dataRef = React.createRef();

        this.handleExecuteStart = this.handleExecuteStart.bind(this);
        this.handleExecute = this.handleExecute.bind(this);
        this.handleNextClick = this.handleNextClick.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.renderRowChild = this.renderRowChild.bind(this);

        this.state = {
            working: false,
        };
    }

    handleExecuteStart(value, results) {
        if (!isNil(results)) {
            return false;
        }

        this.setState({
            working: true,
        });

        return true;
    }

    handleExecute(selectedPatients) {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        const updatedPatients = mapValues(selectedPatients, patient => {
            const {
                destination: {
                    type,
                    checkOutLocation,
                    deceased,
                    transferredTo,
                },
            } = patient;
            let destination = null;

            if (type === Destinations.HOME) {
                destination = { type };
            } else if (type === Destinations.OTHER) {
                destination = { type, checkOutLocation };
            } else if (type === Destinations.DECEASED) {
                destination = { type, deceased, transferredTo };
            }

            return {
                ...patient,
                destination,
            };
        });

        executeAction(startReview, updatedPatients);
    }

    handleNextClick() {
        this.dataRef.current.execute();
    }

    handlePreviousClick() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(cancelDestination);
    }

    handleCancelClick() {
        const {
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(clearStores);
        history.push('/home');
    }

    // eslint-disable-next-line class-methods-use-this
    renderRowChild(item, rowIndex) {
        const { id } = item;
        const typePath = `${id}.destination.type`;

        return (
            <>
                <Cell />
                <Cell />
                <Cell />
                <Cell />
                <Cell colSpan={1}>
                    <DataGroup
                        className="destination-details"
                        hidden={({ get }) =>
                            get(typePath) === Destinations.HOME
                        }
                    >
                        <Row gutter="16">
                            <Column width="5">
                                <DataTextProperty
                                    id="checkOutLocation"
                                    label="Check-Out Location"
                                    aria-label="Check-Out Location"
                                    width="md"
                                    size="sm"
                                    path={`${id}.destination.checkOutLocation`}
                                    minLength={[2, INVALID_VALUE_MSG]}
                                    maxLength={[50, INVALID_VALUE_MSG]}
                                    pattern={[
                                        basicPattern,
                                        INVALID_CHARACTERS_MSG,
                                    ]}
                                    hidden={({ form: { get } }) =>
                                        get(typePath) !== Destinations.OTHER
                                    }
                                />
                                <DataDateProperty
                                    id="deceased"
                                    label="Date of Death"
                                    size="sm"
                                    path={`${id}.destination.deceased`}
                                    minDate={[MIN_DATE, INVALID_VALUE_MSG]}
                                    maxDate={[MAX_DATE, INVALID_VALUE_MSG]}
                                    hidden={({ form: { get } }) =>
                                        get(typePath) !== Destinations.DECEASED
                                    }
                                />
                            </Column>
                            <Column width="5">
                                <DataTextProperty
                                    id="transferredTo"
                                    label="Transferred To"
                                    aria-label="Transferred To"
                                    width="md"
                                    size="sm"
                                    path={`${rowIndex}.destination.transferredTo`}
                                    minLength={[2, INVALID_VALUE_MSG]}
                                    maxLength={[50, INVALID_VALUE_MSG]}
                                    pattern={[
                                        basicPattern,
                                        INVALID_CHARACTERS_MSG,
                                    ]}
                                    hidden={({ form: { get } }) =>
                                        get(typePath) !== Destinations.DECEASED
                                    }
                                />
                            </Column>
                        </Row>
                    </DataGroup>
                </Cell>
            </>
        );
    }

    render() {
        const { selectedPatients } = this.props;
        const { working } = this.state;

        return (
            <div className="check-out-destination-ghigui">
                <PageContainerGroup>
                    <Row gutter="16" enableAfter>
                        <Column width="fill">
                            <Text size="xl" weight="bold">
                                Check-Out Destination
                            </Text>
                        </Column>
                    </Row>
                </PageContainerGroup>
                <Data
                    ref={this.dataRef}
                    baseValue={selectedPatients}
                    validateOnBlur
                    validateOnExecute
                    showResultsOnTouch
                    showResultsOnExecute
                    onValidateStart={this.handleValidateStart}
                    onValidateEnd={this.handleValidateEnd}
                    onExecuteStart={this.handleExecuteStart}
                    onExecute={this.handleExecute}
                >
                    <DataAccess>
                        {({ property: { value } }) => (
                            <SimpleTable
                                id="destination-table"
                                items={selectedPatients}
                                columns={columns}
                                minWidth={900}
                                pageTable
                                useParentScroll
                                renderRowChild={(item, rowIndex) => {
                                    const destinationType = lodashGet(
                                        value,
                                        `${item.id}.destination.type`,
                                    );

                                    if (
                                        destinationType ===
                                        Destinations.HOME
                                    ) {
                                        return null;
                                    }

                                    return this.renderRowChild(
                                        item,
                                        rowIndex,
                                    );
                                }}
                            />
                        )}
                    </DataAccess>
                    <PageContainerGroup>
                        <Row
                            gutter="16"
                            className="check-out-dest-bottom-buttons"
                        >
                            <Column width="fill">
                                <ButtonGroup>
                                    <Button
                                        id="checkOutDestinationNext"
                                        color="secondary"
                                        variant="fill"
                                        disabled={working}
                                        onClick={this.handleNextClick}
                                    >
                                        Next
                                    </Button>

                                    <Button
                                        id="check-out-dest-prev"
                                        color="secondary"
                                        variant="outline"
                                        onClick={this.handlePreviousClick}
                                    >
                                        Previous
                                    </Button>
                                </ButtonGroup>
                            </Column>
                            <Column width="content">
                                <Button
                                    id="check-out-dest-cancel"
                                    color="secondary"
                                    variant="opaque"
                                    onClick={this.handleCancelClick}
                                >
                                    Cancel
                                </Button>
                            </Column>
                        </Row>
                    </PageContainerGroup>
                </Data>
            </div>
        );
    }
}

CheckOutDestination.propTypes = {
    selectedPatients: selectedPatientsPropType,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckOutDestination.defaultProps = {
    selectedPatients: null,
};

export default withRouter(applyFluxibleContext(CheckOutDestination));
