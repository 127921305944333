import get from 'lodash/get';
import map from 'lodash/map';
import dateFormatter from '@audacious/web-common/formatters/dateFormatter';
import exportPatientsCsv from '../../common/util/export-patients-csv';
import printPatients from '../../common/util/print-patients';
import formatPatient from '../../common/util/format-patient';

function formatPatients(patients) {
    const formattedPatients = map(patients, patient => 
    // eslint-disable-next-line no-param-reassign
        ({
        id: patient.mrn,
        patient,
        formatted: formatPatient(patient),
    })
);

    return formattedPatients;
}

function beforeRequest(actionContext, { params, options }) {
    const exportType = get(options, 'exportType');
    if (exportType) {
        actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_START');
    } else {
        actionContext.dispatch('CHECK_OUT_SEARCH_START', {
            firstName: params.firstName,
            lastName: params.lastName,
            page: params.page,
            pageSize: params.pageSize,
        });
    }
}

function onSuccess(actionContext, { response, options, params = {} }) {
    const exportType = get(options, 'exportType');

    if (exportType) {
        const patients = get(response.data, 'checkins', []);
        const userName = get(options, 'userName');
        const facilityName = get(options, 'facilityName');
        const locationName = get(options, 'locationName');
        const date = dateFormatter(new Date().toString(), {
            outFormat: 'MM/DD/YYYY h:mm:ss a',
        });

        if (exportType === 'csv') {
            exportPatientsCsv({
                patients,
                userName,
                facilityName,
                locationName,
                date,
            });
        } else if (exportType === 'print') {
            const formattedPatients = formatPatients(patients);
            printPatients({
                patients: formattedPatients,
                userName,
                facilityName,
                locationName,
                date,
            });
        }
        actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_FINISH');
    } else {
        actionContext.dispatch('CHECK_OUT_SEARCH_SUCCESS', {
            results: response.data,
            page: params.page,
            pageSize: params.pageSize,
        });
    }
}

function onFailed(actionContext, { data, options }) {
    const exportType = get(options, 'exportType');

    if (exportType) {
        actionContext.dispatch('CHECK_OUT_PATIENT_EXPORT_FINISH');
    } else {
        actionContext.dispatch('CHECK_OUT_SEARCH_FAILED', {
            failed: data,
            succeeded: [],
        });
    }
}

export default config => ({
    serviceName: 'runSearchQuery',
    authenticate: true,
    config: {
        method: 'GET',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkIn.searchQuery'),
        // baseUrl: 'http://localhost:3000'
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
