import words from 'lodash/words';
import escapeRegExp from 'lodash/escapeRegExp';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import join from 'lodash/join';
import map from 'lodash/map';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

/**
 * Returns a string from the value in the item at valuePath.
 * If valuePath is an array it returns each value at each path
 * concatenated together into a string separted by spaces.
 */
function getValueFromPath(item, valuePath) {
    if (isArray(valuePath)) {
        return join(
            map(valuePath, path => get(item, path, '')),
            ' ',
        );
    }

    return get(item, valuePath, '');
}

/**
 * Builds the filter regex used to filter table rows from the filterText
 * string. Matching rows must have values strings that partially match each
 * word in filterText.
 */
function buildFilterRegex(filterText) {
    const filterWords = words(filterText, /[^\s]+/g);

    // Construct a regex lookahead for each word, which is how to do AND
    // operators in regex
    let regexStr = '';
    forEach(filterWords, word => {
        regexStr = `${regexStr}(?=.*${escapeRegExp(word)})`;
    });

    return new RegExp(`^${regexStr}.*$`, 'i');
}

function tableItemFilter(items, columns, filterText) {
    let filterRegex = null;

    if (filterText && filterText.length > 0) {
        filterRegex = buildFilterRegex(filterText);
    }

    if (isNil(filterRegex)) {
        return items;
    }

    return filter(items, item => {
        let rowFilterString = '';

        forEach(columns, column => {
            const cellValuePath = get(column, 'cellValuePath', null);
            const filterable = get(column, 'filterable', true);
            const cellFilterPath = get(column, 'cellFilterPath', null);

            if (!filterable) {
                return;
            }

            let cellFilterString = '';

            if (cellFilterPath) {
                cellFilterString = getValueFromPath(item, cellFilterPath);
            } else {
                cellFilterString = getValueFromPath(item, cellValuePath);
            }

            if (!isEmpty(cellFilterString)) {
                rowFilterString = `${rowFilterString} ${cellFilterString}`;
            }
        });

        return rowFilterString.match(filterRegex);
    });
}

export default tableItemFilter;
