import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isNil from 'lodash/isNil';

const datesAreOnSameDay = (first, second) =>
    first.getFullYear() === second.getFullYear() &&
    first.getMonth() === second.getMonth() &&
    first.getDate() === second.getDate();

function filterByDateRange(startDate, endDate, patients, path) {
    if (isNil(startDate)) {
        return patients;
    }

    const filteredPatients = [];

    if (isNil(endDate)) {
        forEach(patients, patient => {
            const pathDate = new Date(get(patient, path));

            if (datesAreOnSameDay(startDate, pathDate)) {
                filteredPatients.push(patient);
            }
        });
    } else {
        forEach(patients, patient => {
            const pathDate = new Date(get(patient, path));

            if (
                (datesAreOnSameDay(startDate, pathDate) ||
                    startDate <= pathDate) &&
                (datesAreOnSameDay(endDate, pathDate) || endDate >= pathDate)
            ) {
                filteredPatients.push(patient);
            }
        });
    }

    return filteredPatients;
}

export default filterByDateRange;
