import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { connectToStores } from 'fluxible-addons-react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import Spinner from '@audacious/components/components/Spinner';
import { withRouter } from 'react-router-dom';
import CheckInStart from './check-in-start';
import CheckInReview from './check-in-review';
import { startCheckIn } from '../../../actions/check-in-actions';
import { checkInPatientPropType } from './check-in-prop-type';
import BasePage from '../../common/base-page';
import { clearStores } from '../../../actions/home-actions';

class CheckIn extends React.Component {
    constructor(...args) {
        super(...args);

        this.handleGoto = this.handleGoto.bind(this);
    }

    componentDidMount() {
        const {
            step,
            fluxibleContext: { executeAction },
        } = this.props;

        if (!isEqual(step, 'bulk-reviewed')) {
            executeAction(startCheckIn);
        }
    }

    handleGoto(route) {
        const {
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(clearStores);

        history.push(route);
    }

    renderStep() {
        const {
            checkIns,
            step,
            bulkData,
            tenantId,
            facilityId,
            isUpdating,
        } = this.props;

        if (step === 'manual-reviewed' || step === 'bulk-reviewed') {
            return (
                <CheckInReview
                    checkIns={checkIns}
                    step={step}
                    tenantId={tenantId}
                    facilityId={facilityId}
                    isUpdating={isUpdating}
                />
            );
        }

        if (step === 'home') {
            const {
                fluxibleContext: { executeAction },
            } = this.props;

            executeAction(startCheckIn);

            this.handleGoto('/home');
        }

        if (step === 'committed') {
            return (
                <Spinner
                    id="checked-in-roster-spinner"
                    variant="overlay"
                    spaceFromTop="sm"
                />
            );
        }

        return <CheckInStart checkIns={checkIns} bulkData={bulkData} />;
    }

    render() {
        return (
            <BasePage
                id="check-in-page"
                breadcrumbs={[
                    {
                        label: 'Home',
                        route: 'home',
                    },
                ]}
                pageName="Check In"
                onGoto={this.handleGoto}
                useFacilitySelection
            >
                {this.renderStep()}
            </BasePage>
        );
    }
}

CheckIn.propTypes = {
    step: PropTypes.bool.isRequired,
    bulkData: PropTypes.shape({
        running: PropTypes.bool,
        error: PropTypes.bool,
    }).isRequired,
    checkIns: PropTypes.arrayOf(
        PropTypes.shape({
            firstName: PropTypes.string,
        }),
    ),
    commitData: PropTypes.shape({
        running: PropTypes.bool,
        results: PropTypes.shape({
            succeeded: PropTypes.arrayOf(checkInPatientPropType),
            failed: PropTypes.arrayOf(checkInPatientPropType),
        }),
        failed: PropTypes.bool,
    }),
    // eslint-disable-next-line react/forbid-prop-types
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    facilityId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckIn.defaultProps = {
    checkIns: null,
    commitData: null,
};

const BoundComponent = connectToStores(
    CheckIn,
    ['CheckInWorkflowStore', 'Session', 'RosterStore'],
    context => {
        const submittedCheckInsStore = context.getStore('CheckInWorkflowStore');

        const step = submittedCheckInsStore.getWorkflowStep();
        const bulkData = submittedCheckInsStore.getBulkData();
        const checkIns = submittedCheckInsStore.getCheckIns();
        const commitData = submittedCheckInsStore.getCommitData();

        const sessionStore = context.getStore('Session');
        const tenantId = sessionStore.getTenant().getId();
        const facility = sessionStore.getFacility();
        const facilityId = facility ? facility.getId() : '';

        const rosterStore = context.getStore('RosterStore');
        const rosterState = rosterStore.getState();

        return {
            step,
            bulkData,
            checkIns,
            commitData,
            tenantId,
            facilityId,
            isUpdating: rosterState.isUpdatingCheckInPatient,
        };
    },
);

export default withRouter(applyFluxibleContext(BoundComponent));
