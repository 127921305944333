import isNil from 'lodash/isNil';
import toUpper from 'lodash/toUpper';
import reduce from 'lodash/reduce';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import parseDate from '@audacious/web-common/utilities/parseDate';
import usaStates from 'usa-states';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import phoneFormatter from '@audacious/web-common/formatters/phoneFormatter';
import {
    requiredValidator,
    minLengthValidator,
    maxLengthValidator,
    patternValidator,
    minDateValidator,
    maxDateValidator,
    dateValidator,
} from '@audacious/components/validators';
import {
    namePattern,
    zipCodePattern,
    countryCodePattern,
    phonePattern,
    basicPattern,
    socialPattern,
} from '../constants/reg-ex-patterns';
import { MIN_DATE, MAX_DATE } from '../constants/dates';

const INVALID_DATE = new Date(NaN);

const provinces = new usaStates.UsaStates({
    includeTerritories: true,
});

const provinceLookup = reduce(
    provinces.states,
    (acc, state) => {
        acc[state.abbreviation] = state;

        return acc;
    },
    {},
);

function stateValidator(value) {
    if (isNil(value)) {
        return null;
    }

    const province = provinceLookup[value];

    if (!isNil(province)) {
        return null;
    }

    return 'Invalid State';
}

function genderValidator(value) {
    if (isNil(value) || isEmpty(value)) {
        return null;
    }

    const results = genderFormatter(value);

    if (isEmpty(results)) {
        return 'Invalid Gender Value';
    }

    return null;
}

function pushIfNotNil(array, result) {
    if (!isNil(result)) {
        array.push(result);

        return false;
    }

    return true;
}

function parseString(value, capitalize) {
    if (isNil(value) || value.length <= 0) {
        return null;
    }

    if (capitalize) {
        return toUpper(value);
    }

    return value;
}

function parseDob(value) {
    if (!isString(value) || value.length <= 0) {
        return null;
    }

    try {
        return parseDate(value);
    } catch (error) {
        return INVALID_DATE;
    }
}

export const definitionOrder = [
    'first name',
    'middle name',
    'last name',
    'dob',
    'gender',
    'address line 1',
    'address line 2',
    'city',
    'state',
    'zip code',
    'country code',
    'phone',
    'social security number',
];

export const columnDefinitions = {
    'first name': {
        id: 'firstName',
        path: 'firstName',
        required: true,
        label: 'First Name',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(messages, minLengthValidator(value, 2));
            pushIfNotNil(messages, maxLengthValidator(value, 50));
            pushIfNotNil(messages, patternValidator(value, namePattern));

            return {
                value,
                messages,
            };
        },
    },
    'middle name': {
        id: 'middleName',
        path: 'middleName',
        required: false,
        label: 'Middle Name',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, maxLengthValidator(value, 50));
            pushIfNotNil(messages, patternValidator(value, namePattern));

            return {
                value,
                messages,
            };
        },
    },
    'last name': {
        id: 'lastName',
        path: 'lastName',
        required: true,
        label: 'Last Name',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(messages, minLengthValidator(value, 2));
            pushIfNotNil(messages, maxLengthValidator(value, 50));
            pushIfNotNil(messages, patternValidator(value, namePattern));

            return {
                value,
                messages,
            };
        },
    },
    dob: {
        id: 'dob',
        path: 'dob',
        required: true,
        label: 'DOB',
        process: rawValue => {
            const messages = [];

            let value = parseDob(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(messages, dateValidator(value));
            pushIfNotNil(messages, minDateValidator(value, MIN_DATE));
            pushIfNotNil(messages, maxDateValidator(value, MAX_DATE));

            if (messages.length > 0) {
                value = '';
            }

            return {
                value,
                messages,
            };
        },
    },
    gender: {
        id: 'gender',
        path: 'gender',
        required: true,
        label: 'Gender',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(messages, genderValidator(value));

            return {
                value,
                messages,
            };
        },
    },
    'address line 1': {
        id: 'address1',
        path: 'address1',
        required: false,
        label: 'Address Line 1',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, minLengthValidator(value, 2));
            pushIfNotNil(messages, maxLengthValidator(value, 255));
            pushIfNotNil(messages, patternValidator(value, basicPattern));

            return {
                value,
                messages,
            };
        },
    },
    'address line 2': {
        id: 'addressLine2',
        path: 'address2',
        required: false,
        label: 'Address Line 2',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, minLengthValidator(value, 2));
            pushIfNotNil(messages, maxLengthValidator(value, 255));
            pushIfNotNil(messages, patternValidator(value, basicPattern));

            return {
                value,
                messages,
            };
        },
    },
    city: {
        id: 'city',
        path: 'city',
        required: false,
        label: 'City',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, minLengthValidator(value, 2));
            pushIfNotNil(messages, maxLengthValidator(value, 35));
            pushIfNotNil(messages, patternValidator(value, basicPattern));

            return {
                value,
                messages,
            };
        },
    },
    state: {
        id: 'state',
        path: 'state',
        required: false,
        label: 'State',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);

            pushIfNotNil(messages, stateValidator(value));

            return {
                value,
                messages,
            };
        },
    },
    'zip code': {
        id: 'zip',
        path: 'zip',
        required: true,
        label: 'Zip Code',
        process: rawValue => {
            const messages = [];

            let value = parseString(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(
                messages,
                patternValidator(value, zipCodePattern, 'Invalid format'),
            );

            if (messages.length > 0) {
                value = '';
            }

            return {
                value,
                messages,
            };
        },
    },
    'country code': {
        id: 'countryCode',
        path: 'countryCode',
        required: true,
        label: 'Country Code',
        process: rawValue => {
            const messages = [];

            let value = '+1';

            if (!isNil(rawValue)) {
                value = parseString(rawValue);

                if (!value.startsWith('+')) {
                    value = `+${value}`;
                }

                pushIfNotNil(
                    messages,
                    patternValidator(
                        value,
                        countryCodePattern,
                        'The country code is invalid. Please enter a valid country code.',
                    ),
                );

                if (messages.length > 0) {
                    value = '';
                }
            }

            return {
                value,
                messages,
            };
        },
    },
    phone: {
        id: 'phone',
        path: 'phone',
        required: true,
        label: 'Phone',
        process: rawValue => {
            const messages = [];

            let value = phoneFormatter(rawValue);

            pushIfNotNil(messages, requiredValidator(value));
            pushIfNotNil(
                messages,
                patternValidator(
                    value,
                    phonePattern,
                    'The phone number is invalid. Please enter a ten-digit phone number.',
                ),
            );

            if (messages.length > 0) {
                value = '';
            }

            return {
                value,
                messages,
            };
        },
    },
    'social security number': {
        id: 'ssn',
        path: 'ssn',
        required: false,
        label: 'Social Security Number',
        process: rawValue => {
            const messages = [];

            const value = parseString(rawValue);
            pushIfNotNil(
                messages,
                patternValidator(
                    value,
                    socialPattern,
                    'The social security number is invalid. Please enter a 4 or 9 digit SSN.',
                ),
            );

            return {
                value,
                messages,
            };
        },
    },
};
