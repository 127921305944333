import map from 'lodash/map';
import forEach from 'lodash/forEach';
import trim from 'lodash/trim';
import get from 'lodash/get';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import moment from 'moment';
import capitalize from 'lodash/capitalize';

function getFormattedDate(dateTime) {
    if (!isNil(dateTime)) {
        const dateTimeObj = new Date(trim(dateTime));
        const dateObj = new Date(trim(dateTimeObj));

        return `${dateObj.getMonth() +
            1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
    }

    return '';
}

function getFormattedDateOfDeath(dateOfDeath) {
    return moment(dateOfDeath).format('MM/DD/YYYY');
}

function exportPatientsCsv({
    patients,
    userName,
    facilityName,
    locationName,
    date,
    eventType = 'checked-in',
}) {
    const formattedPatients = map(patients, patientObj => {
        const patient = get(patientObj, 'patient', {});

        return {
            firstName: trim(patient.firstName),
            lastName: trim(patient.lastName),
            middleName: trim(patient.middleName),
            address: `${trim(patient.address1)} ${trim(patient.address2)}`,
            dob: trim(patient.dob),
            gender: genderFormatter(patient.gender),
            city: trim(patient.city),
            state: trim(patient.state),
            zip: trim(patient.zip),
            phone: `${trim(patient.countryCode)} ${trim(patient.phone)}`,
            checkInDate: getFormattedDate(patient.checkinTime),
            checkOutDate: getFormattedDate(patient.checkoutTime),
            dateOfDeath: !isNil(patient.dateOfDeath)
                ? getFormattedDateOfDeath(patient.dateOfDeath)
                : '',
            checkOutType: trim(patient.checkoutType),
            checkOutLocation: trim(patient.checkoutLocation),
        };
    });

    let dataString = '';

    dataString += `User Name,"${userName}"%0A`;
    dataString += `Facility,"${facilityName}"%0A`;
    dataString += `Location,"${locationName}"%0A`;
    dataString += `Date,"${date}"%0A`;
    dataString += '%0A';

    dataString +=
        'Last Name,First Name,Middle Name,DOB,Gender,Address,City,State,Zip Code,Phone,Check-in Date';

    if (isEqual(eventType, 'checked-out')) {
        dataString += ',Check-out Date,Check-out Destination';
    }

    dataString += '%0A';

    forEach(formattedPatients, patient => {
        dataString += `"${patient.lastName}",`;
        dataString += `"${patient.firstName}",`;
        dataString += `"${patient.middleName}",`;
        dataString += `${patient.dob},`;
        dataString += `${patient.gender},`;
        dataString += `"${patient.address}",`;
        dataString += `"${patient.city}",`;
        dataString += `"${patient.state}",`;
        dataString += `"${patient.zip}",`;
        dataString += `${patient.phone},`;
        dataString += `${patient.checkInDate}`;

        if (isEqual(eventType, 'checked-out')) {
            dataString += `,${patient.checkOutDate},`;
            dataString += `${capitalize(patient.checkOutType)}`;
            if (!isEmpty(patient.dateOfDeath)) {
                dataString += ` (${patient.dateOfDeath})`;
            }
            if (!isEmpty(patient.checkOutLocation)) {
                dataString += ` - ${patient.checkOutLocation}`;
            }
        }

        dataString += '%0A';
    });

    const link = document.createElement('a');
    link.href = `data:text/csv;charset=UTF-8,${dataString.replaceAll(
        '#',
        '%23',
    )}`; // Pound symbol breaks CSV, so encode it

    if (isEqual(eventType, 'checked-out')) {
        link.download = 'checked-out-patients.csv';
    } else {
        link.download = 'checked-in-patients.csv';
    }

    link.click();
}

export default exportPatientsCsv;
