import get from 'lodash/get';

function beforeRequest(actionContext) {
    actionContext.dispatch('CHECK_OUT_PATIENTS_START');
}

function onSuccess(actionContext, { response }) {
    const { notifications, dispatch } = actionContext;

    const { successes, failures } = response.data;

    dispatch('CHECK_OUT_PATIENTS_FINISH', [null, successes]);

    notifications.alert.clear();

    notifications.alert.set({
        heading: 'Check-Out Completed',
        message: `${successes.length} of ${successes.length +
            failures.length} successfully checked out.`,
        color: 'success',
    });

    setTimeout(() => {
        notifications.alert.clear();

        dispatch('CHECK_OUT_CLEAR_NEW_PATIENTS', [null, successes]);
    }, 9000);
}

function onFailed(actionContext, { error }) {
    const { notifications, dispatch } = actionContext;

    dispatch('CHECK_OUT_PATIENTS_FINISH', [error]);

    notifications.alert.set({
        heading: 'Check-Out Failed',
        message: 'Failed to successfully check out selected patients.',
        color: 'danger',
        actions: [
            {
                onPerform: () => {
                    notifications.alert.clear();
                },
                label: 'Ok',
            },
        ],
        blocking: true,
    });
}

export default config => ({
    serviceName: 'createCheckOuts',
    authenticate: true,
    config: {
        method: 'POST',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkOut.create'),
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
