import map from 'lodash/map';
import includes from 'lodash/includes';
import toLower from 'lodash/toLower';
import GenderOptions from './gender-options';

function validateGender(value) {
    const genderValues = map(GenderOptions, gender => toLower(gender.text));

    if (!value) {
        return null;
    }

    if (!includes(genderValues, toLower(value))) {
        return 'Enter a valid gender';
    }

    return null;
}

export default validateGender;
