import React from 'react';
import PropTypes from 'prop-types';
import { AuthenticatedPage } from '@audacious/client';
import FacilitySelection, {
    FacilityHeaderFeature,
} from '@audacious/pulse-facility-selection';
import MainPageContent from './main-page-content';
import './main-page.scss';

function MainPage(props) {
    const { listType } = props;

    return (
        <AuthenticatedPage
            id="ec-main-page"
            pageName="EC"
            additionalFeatures={[
                <FacilityHeaderFeature key="FacilityHeaderFeature" />,
            ]}
            variant="grey"
            hideTitle
            hideFooter
        >
            <FacilitySelection>
                <MainPageContent listType={listType} />
            </FacilitySelection>
        </AuthenticatedPage>
    );
}

MainPage.propTypes = {
    listType: PropTypes.string.isRequired,
};

MainPage.defaultProps = {};

export default MainPage;
