/* eslint-disable import/prefer-default-export */
/* eslint-disable no-console */

import PapaParse from 'papaparse';
import map from 'lodash/map';
import set from 'lodash/set';
import trim from 'lodash/trim';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import reduce from 'lodash/reduce';
import endsWith from 'lodash/endsWith';
import toLower from 'lodash/toLower';
import Promise from 'bluebird';
import { isEmpty } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { columnDefinitions } from '../csv/column-definitions';

function buildColumnLookup(columnHeaders) {
    const columnBreakdown = [];
    const quickDefinitionClone = { ...columnDefinitions };

    forEach(columnHeaders, columnHeader => {
        const columnName = toLower(columnHeader);

        const columnDefinition = quickDefinitionClone[columnName];

        if (isNil(columnDefinition)) {
            throw new Error(`Invalid Column "${columnName}"`);
        }

        columnBreakdown.push(columnDefinition);

        quickDefinitionClone[columnName] = null;
    });

    forEach(quickDefinitionClone, columnDefinition => {
        if (isNil(columnDefinition) || !columnDefinition.required) {
            return;
        }

        throw new Error(`Missing required column "${columnDefinition.label}"`);
    });

    return columnBreakdown;
}

function convertToCheckIn(line, columnBreakdown) {
    const patient = {};
    const original = {};
    const issues = [];

    const lineMessages = [];

    if (line.length < columnBreakdown.length) {
        lineMessages.push('Too few columns');
    }

    if (line.length > columnBreakdown.length) {
        lineMessages.push('Too many columns');
    }

    if (lineMessages.length > 0) {
        issues.push({
            path: '',
            label: 'General',
            messages: lineMessages,
        });
    }

    let hasValues = false;

    forEach(columnBreakdown, (columnDefinition, index) => {
        set(original, columnDefinition.path, line[index]);

        let lineItem = trim(line[index]);

        if (!isEmpty(lineItem)) {
            // We just we just want to know if the original value even existed.
            // Some values will default to a value and we don't want to mistake
            // that for a real value if there was none originally.
            hasValues = true;
        } else {
            lineItem = null;
        }

        const { value, messages } = columnDefinition.process(lineItem);

        if (!isEmpty(messages)) {
            issues.push({
                label: columnDefinition.label,
                path: columnDefinition.path,
                messages,
            });
        }

        set(patient, columnDefinition.path, value);
    });

    set(patient, 'tempId', uuidv4());

    if (!hasValues) {
        return null;
    }

    return {
        issues,
        patient,
        original,
    };
}

function processFile(file) {
    if (!endsWith(file.name, '.csv')) {
        throw new Error('Invalid file type');
    }

    return Promise.fromCallback(callback => {
        PapaParse.parse(file, {
            complete: result => {
                const { errors, data } = result;

                let executeError = null;

                if (!isNil(errors) && errors.length > 0) {
                    forEach(errors, error => console.error(error.message));

                    executeError = new Error('Selected File(s) are invalid');
                }

                callback(executeError, data);
            },
        });
    }).then(lines => {
        const checkIns = [];

        const columnLookup = buildColumnLookup(lines[0]);

        for (let counter = 1; counter < lines.length; counter += 1) {
            const line = lines[counter];

            const checkIn = convertToCheckIn(line, columnLookup);

            if (!isNil(checkIn)) {
                checkIns.push(checkIn);
            }
        }

        return checkIns;
    });
}

export function processFiles(context, files) {
    context.dispatch('CHECK_IN_BULK_UPLOAD_START');

    return Promise.delay(100)
        .then(() => {
            const proms = map(files, file => processFile(file));

            return Promise.all(proms);
        })
        .then(fileCheckIns => reduce(
                fileCheckIns,
                (acc, checkIns) => acc.concat(checkIns),
                [],
            ))
        .then(checkIns => {
            context.dispatch('CHECK_IN_BULK_UPLOAD_FINISH', checkIns);
        })
        .catch(error => {
            context.dispatch('CHECK_IN_BULK_UPLOAD_FAILED', error);
        });
}
