import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

function beforeRequest(actionContext) {
    actionContext.dispatch('EDIT_CHECK_IN_PATIENT_START');
}

function onSuccess(actionContext, { response }) {
    const { successes: succeeded, failures: failed } = response.data;

    if (!isEmpty(failed)) {
        actionContext.dispatch('EDIT_CHECK_IN_PATIENT_FAILED', {
            failed: failed[0],
        });
    } else {
        actionContext.dispatch('EDIT_CHECK_IN_PATIENT_SUCCESS', {
            succeeded,
            failed,
        });
    }
}

function onFailed(actionContext, { error }) {
    actionContext.dispatch('EDIT_CHECK_IN_PATIENT_FAILED', {
        failed: error,
        succeeded: [],
    });
}

export default config => ({
    serviceName: 'updateCheckIn',
    authenticate: true,
    config: {
        method: 'PUT',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkIn.edit'),
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
