import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    PageContainer,
    PageTitle,
} from '@audacious/components/components/Page';
import { Row, Column } from '@audacious/components/components/Grid';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Button from '@audacious/components/components/Button';
import { Text } from '@audacious/components/components/Typography';
import getDateParts from '@audacious/web-common/utilities/getDateParts';
import AlertMessage from '@audacious/components/components/AlertMessage';
import CheckInput from '@audacious/components/components/CheckInput';
import { faExclamationCircle } from '@audacious/icons/regular/faExclamationCircle';
import { faPencil } from '@audacious/icons/regular/faPencil';
import PageNotification from '@audacious/components/components/PageNotification';
import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import forEach from 'lodash/forEach';
import isNil from 'lodash/isNil';
import get from 'lodash/get';
import clone from 'lodash/clone';
import isEqual from 'lodash/isEqual';
import {
    goBackToPrevious,
    goBackToHomeWithDrawerOpen,
    checkinThenGoHome,
    removeInvalidCheckIns,
} from '../../../actions/check-in-actions';
import { checkInsPropType } from './check-in-prop-type';
import ConfirmationTable from '../../common/confirmation-table';
import EditDrawer from '../main-page/edit-drawer/edit-drawer';
import './check-in.scss';

class CheckInReview extends React.Component {
    constructor(props) {
        super(props);

        this.handleCheckInClick = this.handleCheckInClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.handleEditPatient = this.handleEditPatient.bind(this);
        this.handleEditPatientSave = this.handleEditPatientSave.bind(this);
        this.handleCancelEditPatient = this.handleCancelEditPatient.bind(this);
        this.handleShouldDownloadLogChange = this.handleShouldDownloadLogChange.bind(
            this,
        );
        this.showRemoveErrorRecordsConfirmation = this.showRemoveErrorRecordsConfirmation.bind(
            this,
        );
        this.hideRemoveErrorRecordsConfirmation = this.hideRemoveErrorRecordsConfirmation.bind(
            this,
        );
        this.handleRemoveErrorRecords = this.handleRemoveErrorRecords.bind(
            this,
        );

        this.hideErrorPopup = this.hideErrorPopup.bind(this);

        this.state = {
            patientBeingEdited: null,
            removeErrorRecordsConfirmation: false,
            showErrorPopup: false,
            downloadErrorLog: true,
        };
    }

    handleRemoveErrorRecords() {
        const {
            checkIns,
            fluxibleContext: { executeAction },
        } = this.props;
        const { downloadErrorLog } = this.state;

        executeAction(removeInvalidCheckIns, { checkIns, downloadErrorLog });

        this.setState({
            removeErrorRecordsConfirmation: false,
        });
    }

    handleCheckInClick() {
        const { history, tenantId, facilityId, checkIns } = this.props;

        let errors = false;

        const patients = reduce(
            checkIns,
            (acc, { issues, patient }) => {
                if (isEmpty(issues)) {
                    const copy = clone(patient);
                    const dob = get(copy, 'dob', null);
                    copy.dob = getDateParts(dob);

                    acc.push(copy);
                } else {
                    errors = true;
                }

                return acc;
            },
            [],
        );

        const {
            fluxibleContext: { executeAction },
        } = this.props;

        if (!errors) {
            executeAction(checkinThenGoHome, {
                tenantId,
                facilityId,
                patients,
                history,
            });
        } else {
            this.setState({ showErrorPopup: true });
        }
    }

    handleCancelClick() {
        const {
            step,
            fluxibleContext: { executeAction },
        } = this.props;

        if (isEqual(step, 'bulk-reviewed')) {
            executeAction(goBackToHomeWithDrawerOpen);
        } else {
            executeAction(goBackToPrevious);
        }
    }

    handleEditPatient({ meta }) {
        const patientMeta = meta;

        this.setState({ patientBeingEdited: patientMeta });
    }

    handleEditPatientSave() {
        this.setState({ patientBeingEdited: null });
    }

    handleCancelEditPatient() {
        this.setState({
            patientBeingEdited: null,
        });
    }

    handleShouldDownloadLogChange(value) {
        this.setState({ downloadErrorLog: value });
    }

    showRemoveErrorRecordsConfirmation() {
        this.setState({ removeErrorRecordsConfirmation: true });
    }

    hideErrorPopup() {
        this.setState({ showErrorPopup: false });
    }

    hideRemoveErrorRecordsConfirmation() {
        this.setState({ removeErrorRecordsConfirmation: false });
    }

    // eslint-disable-next-line class-methods-use-this
    renderSingleCheckInDetails(readyItems) {
        const patient = readyItems[0];
        const formattedPatient = readyItems[0].formatted;
        const curDate = new Date();
        const formattedDate = moment(curDate).format('MM/DD/YYYY');

        return (
            <>
                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="full-name-value-txt text-align-with-edit">
                            <strong>{formattedPatient.name}</strong>
                        </Text>

                        <Button
                            id="edit-btn"
                            leftIcon={faPencil}
                            variant="none"
                            color="primary"
                            size="sm"
                            onClick={this.handleEditPatient}
                            onClickMeta={patient}
                        >
                            Edit
                        </Button>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="dob-label-txt text-full">
                            <strong>DOB</strong>
                        </Text>
                        <Text className="dob-value-txt">
                            {formattedPatient.dobString}
                        </Text>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="gender-label-txt text-full">
                            <strong>Gender</strong>
                        </Text>
                        <Text className="gender-value-txt">
                            {formattedPatient.gender}
                        </Text>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="address-label-txt text-full">
                            <strong>Address</strong>
                        </Text>
                        <Text className="addressline1-value-txt text-full">
                            {formattedPatient.address.address1}
                        </Text>
                        <Text className="addressline2-value-txt text-full">
                            {formattedPatient.address.address2}
                        </Text>
                        <Text className="city-state-value-txt text-full">
                            {formattedPatient.cityState}
                        </Text>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="phone-label-txt text-full">
                            <strong>Phone</strong>
                        </Text>
                        <Text className="phone-value-txt">
                            {formattedPatient.phone}
                        </Text>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="ssn-label-txt text-full">
                            <strong>Social Security Number</strong>
                        </Text>
                        <Text className="ssn-value-txt">
                            {formattedPatient.ssn}
                        </Text>
                    </Column>
                </Row>

                <Row gutter="16">
                    <Column width={[null, '4']}>
                        <Text className="checked-in-date-label-txt text-full">
                            <strong>Checked-In Date</strong>
                        </Text>
                        <Text className="checked-in-date-value-txt">
                            {formattedDate}
                        </Text>
                    </Column>
                </Row>
            </>
        );
    }

    renderEditDrawer() {
        const { facilityId, isUpdating } = this.props;
        const { patientBeingEdited } = this.state;
        const open = !isNil(patientBeingEdited);

        if (!open) {
            return null;
        }

        return (
            <EditDrawer
                initialData={patientBeingEdited}
                onCancel={this.handleCancelEditPatient}
                onEditPatientSave={this.handleEditPatientSave}
                facilityId={facilityId}
                isUpdating={isUpdating}
                isCheckedIn={false}
            />
        );
    }

    render() {
        const { step, facilityId, isUpdating, checkIns } = this.props;

        const {
            removeErrorRecordsConfirmation,
            showErrorPopup,
            downloadErrorLog,
        } = this.state;

        if (step === 'committed') {
            return null;
        }

        const readyItems = [];
        const invalidItems = [];

        forEach(checkIns, checkIn => {
            if (isEmpty(checkIn.issues)) {
                readyItems.push(checkIn);
            } else {
                invalidItems.push(checkIn);
            }
        });

        let readyList = null;

        if (readyItems.length > 0 || invalidItems.length > 0) {
            if (step === 'manual-reviewed') {
                readyList = this.renderSingleCheckInDetails(readyItems);
            } else {
                const hasInvalidCheckIns = invalidItems.length > 0;
                readyList = (
                    <>
                        {hasInvalidCheckIns ? (
                            <AlertMessage
                                color="danger"
                                icon={faExclamationCircle}
                                message={`To continue check in, please remove or edit the error records (${invalidItems.length} of ${checkIns.length}). Or cancel the Check-In.`}
                                size="xs"
                                className="ec-error-remove-alert"
                                action={{
                                    label: 'Remove Error Records',
                                    onClick: this
                                        .showRemoveErrorRecordsConfirmation,
                                }}
                            />
                        ) : null}
                        <ConfirmationTable
                            items={checkIns}
                            facilityId={facilityId}
                            isUpdating={isUpdating}
                            hasInvalidCheckIns
                            eventType="check-in"
                        />
                    </>
                );
            }
        }
        return (
            <>
                <PageTitle pageName="Review Check-In" />

                {removeErrorRecordsConfirmation ? (
                    <PageNotification
                        actions={[
                            {
                                label: 'Remove',
                                matchColor: true,
                                onClick: this.handleRemoveErrorRecords,
                            },
                            {
                                label: 'Cancel',
                                matchColor: true,
                                onClick: this
                                    .hideRemoveErrorRecordsConfirmation,
                            },
                        ]}
                        color="danger"
                        heading="Remove All Error Records"
                        message={(
                            <>
                                <div>
                                    {`Remove error records (${invalidItems.length} of ${checkIns.length}) from Check-In.`}
                                </div>
                                <div>
                                    <CheckInput
                                        id="download-error-log-check-input"
                                        size="sm"
                                        haloSize="sm"
                                        label="Download Error Log"
                                        value={downloadErrorLog}
                                        onChange={
                                            this.handleShouldDownloadLogChange
                                        }
                                    />
                                </div>
                            </>
                          )}
                    />
                ) : null}

                {showErrorPopup ? (
                    <PageNotification
                        actions={[
                            {
                                label: 'Okay',
                                matchColor: false,
                                onClick: this.hideErrorPopup,
                            },
                        ]}
                        showIcon
                        color="danger"
                        heading="Remove Error Records"
                        message="Check-In records can not contain any errors. Please remove error records or cancel the check-in."
                    />
                ) : null}

                <PageContainer asCard allowScroll>
                    {readyList}

                    <Row gutter="16">
                        <Column>
                            <ButtonGroup className="footer-group">
                                <Button
                                    id="checkInDone"
                                    color="secondary"
                                    variant="fill"
                                    onClick={this.handleCheckInClick}
                                    disabled={
                                        (readyItems.length <= 0 &&
                                            invalidItems <= 0) ||
                                        removeErrorRecordsConfirmation
                                    }
                                >
                                    Check-In
                                </Button>
                                <Button
                                    id="checkInCancel"
                                    color="secondary"
                                    variant="opaque"
                                    onClick={this.handleCancelClick}
                                    disabled={removeErrorRecordsConfirmation}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </Column>
                    </Row>
                </PageContainer>

                {this.renderEditDrawer()}
            </>
        );
    }
}

CheckInReview.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    checkIns: checkInsPropType.isRequired,
    step: PropTypes.string.isRequired,
    facilityId: PropTypes.string.isRequired,
    tenantId: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckInReview.defaultProps = {};

export default withRouter(applyFluxibleContext(CheckInReview));
