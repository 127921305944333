import PropTypes from 'prop-types';
import React, { PureComponent, createRef } from 'react';
import Dialog from '@audacious/components/components/Dialog';
import Button from '@audacious/components/components/Button';
import Document, {
    DocumentHeader,
    DocumentBody,
    DocumentFooter,
} from '@audacious/components/components/Document';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import { SubHeading } from '@audacious/components/components/Typography';
import Spinner from '@audacious/components/components/Spinner';
import isNil from 'lodash/isNil';
import EditForm from './edit-form';
import patientPropType from '../patient-prop-type';
import './edit-drawer.scss';

class EditDrawer extends PureComponent {
    constructor(props) {
        super(props);

        this.dataRef = createRef();

        this.handleSave = this.handleSave.bind(this);
        this.handleExecuteStart = this.handleExecuteStart.bind(this);
    }

    // eslint-disable-next-line class-methods-use-this
    handleExecuteStart(value, results) {
        if (!isNil(results)) {
            return false;
        }
        return true;
    }

    handleSave() {
        this.dataRef.current.execute();
    }

    renderBodyContent() {
        const {
            initialData,
            facilityId,
            isCheckedIn,
            onEditPatientSave: handleEditPatientSave,
        } = this.props;

        return (
            <EditForm
                dataRef={this.dataRef}
                initialData={initialData}
                facilityId={facilityId}
                isCheckedIn={isCheckedIn}
                onEditPatientSave={handleEditPatientSave}
            />
        );
    }

    render() {
        const { onCancel, isUpdating } = this.props;

        return (
            <Dialog
                open
                className="ec-edit-drawer"
                size="sm"
                variant="drawer-right"
                // isSaving={isSaving}
            >
                {isUpdating ? (
                    <Spinner
                        id="checked-in-roster-spinner"
                        variant="overlay"
                        spaceFromTop="sm"
                    />
                ) : null}

                <Document size="sm">
                    <DocumentHeader>
                        <SubHeading level="4">Edit Details</SubHeading>
                    </DocumentHeader>

                    <DocumentBody>{this.renderBodyContent()}</DocumentBody>
                    <DocumentFooter>
                        <ButtonGroup>
                            <Button
                                id="save-edit-btn"
                                color="primary"
                                variant="fill"
                                onClick={this.handleSave}
                            >
                                Save
                            </Button>
                            <Button
                                id="cancel-edit-btn"
                                color="primary"
                                variant="opaque"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>
                        </ButtonGroup>
                    </DocumentFooter>
                </Document>
            </Dialog>
        );
    }
}

EditDrawer.propTypes = {
    initialData: PropTypes.arrayOf(patientPropType).isRequired,
    onCancel: PropTypes.func.isRequired,
    facilityId: PropTypes.string.isRequired,
    isUpdating: PropTypes.bool.isRequired,
    isCheckedIn: PropTypes.bool.isRequired,
    onEditPatientSave: PropTypes.func,
};

EditDrawer.defaultProps = {
    onEditPatientSave: null,
};
export default EditDrawer;
