import usaStates from 'usa-states';
import map from 'lodash/map';

const statesAndTerritories = new usaStates.UsaStates({
    includeTerritories: true,
});

const StatesOptions = map(statesAndTerritories.states, state => ({
    key: state.abbreviation,
    text: `${state.abbreviation} - ${state.name}`,
    selectedText: state.abbreviation,
}));

export default StatesOptions;
