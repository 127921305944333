import { MAX_DATE } from '../constants/dates';

function validateDob(value) {
    if (!value) {
        return null;
    }
    const dateValue = new Date(value);
    if (Number.isNaN(dateValue.getTime())) {
        return 'Enter a valid value';
    }

    if (dateValue > MAX_DATE) {
        return 'Future dates are invalid';
    }

    return null;
}

export default validateDob;
