import PropTypes from 'prop-types';

const patientPropType = PropTypes.shape({
    formatted: PropTypes.shape({
        name: PropTypes.string,
        address: PropTypes.string,
        dob: PropTypes.instanceOf(Date),
        phone: PropTypes.string,
        gender: PropTypes.string,
    }),
});

export default patientPropType;
