/* eslint-disable react/no-access-state-in-setstate */
import map from 'lodash/map';
import isEqual from 'lodash/isEqual';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import findIndex from 'lodash/findIndex';
import formatPatient from '../common/util/format-patient';

const initialState = {
    step: null,
    checkIns: null,
    preventPatientsLoading: false,
    bulk: {
        running: false,
        error: null,
        drawerOpen: false,
    },
    commit: {
        running: false,
    },
    update: {
        running: false,
        succeeded: [],
        failed: [],
    },
};

function formatCheckIns(checkIns) {
    return map(checkIns, checkIn => {
        const { patient } = checkIn;

        return {
            ...checkIn,
            formatted: formatPatient(patient),
        };
    });
}

class CheckInWorkflowStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    // This will reset everything. If you want to move back to here from Review you need to call "previous".
    startCheckIn() {
        this.setState({
            ...initialState,
            step: 'started',
        });
    }

    // This will start the bulk process
    bulkUploadStart() {
        if (this.state.step !== 'started') {
            throw new Error('Must be on started');
        }

        this.setState({
            ...this.state,
            bulk: {
                running: true,
                error: null,
                drawerOpen: false,
            },
        });
    }

    bulkUploadFinish(checkIns) {
        const formattedCheckIns = formatCheckIns(checkIns);

        this.setState({
            ...this.state,
            step: 'bulk-reviewed',
            checkIns: formattedCheckIns,
            preventPatientsLoading: false,
            bulk: {
                running: false,
                error: null,
                drawerOpen: false,
            },
        });
    }

    bulkUploadFailed(error) {
        this.setState({
            ...this.state,
            bulk: {
                running: false,
                error: error.message,
                drawerOpen: false,
            },
        });
    }

    reviewCheckIns(checkIns) {
        const formattedCheckIns = formatCheckIns(checkIns);

        this.setState({
            ...this.state,
            step: 'manual-reviewed',
            checkIns: formattedCheckIns,
            preventPatientsLoading: false,
            bulk: {
                running: false,
                error: null,
                drawerOpen: false,
            },
        });
    }

    setCheckIns(checkIns) {
        this.setState({
            checkIns,
        });
    }

    updateReviewCheckIns(updatedCheckInPatient) {
        const newState = this.getState();

        const { checkIns } = newState;

        const index = findIndex(checkIns, checkIn =>
            isEqual(
                updatedCheckInPatient.patient.tempId,
                checkIn.patient.tempId,
            ),
        );

        if (index >= 0) {
            const formattedPatients = formatCheckIns([updatedCheckInPatient]);

            checkIns[index] = {
                ...formattedPatients[0],
            };
        }

        this.setState({
            ...this.state,
            checkIns,
            bulk: {
                running: false,
                error: null,
                drawerOpen: false,
            },
        });
    }

    resetCheckIns() {
        this.setState({
            ...this.state,
            checkIns: null,
            preventPatientsLoading: true,
            step: 'home',
        });
    }

    previous() {
        const { step } = this.state;

        // Setting preventPatientsLoading only for bulk. For Single checkin it will be handles in resetCheckins.
        if (step === 'manual-reviewed') {
            this.setState({
                ...this.state,
                step: 'started',
            });
        } else if (step === 'bulk-reviewed') {
            this.setState({
                ...this.state,
                preventPatientsLoading: true,
                step: 'home',
                checkIns: null,
            });
        } else {
            throw new Error('Cannot go back.');
        }
    }

    previousWithDrawerOpen() {
        this.setState({
            ...this.state,
            preventPatientsLoading: true,
            step: 'home',
            checkIns: null,
            bulk: {
                drawerOpen: true,
            },
        });
    }

    commitCheckStart() {
        this.setState({
            ...this.state,
            step: 'committed',
            commit: {
                ...this.state.commit,
                running: true,
                failed: [],
            },
        });
    }

    commitCheckInFinish() {
        this.setState({
            ...this.state,
            step: 'committed',
            checkIns: null,
            preventPatientsLoading: false,
            commit: {
                running: false,
            },
        });
    }

    commitCheckInFailed() {
        this.setState({
            ...this.state,
            step: 'error',
            checkIns: null,
            preventPatientsLoading: false,
            commit: {
                running: false,
            },
        });
    }

    getWorkflowStep() {
        return this.getState().step;
    }

    getBulkData() {
        return this.getState().bulk;
    }

    getCheckIns() {
        return this.getState().checkIns;
    }

    getCommitData() {
        return this.getState().commit;
    }

    getUpdateData() {
        return this.getState().update;
    }

    getPreventPatientsLoading() {
        return this.getState().preventPatientsLoading;
    }
}

CheckInWorkflowStore.storeName = 'CheckInWorkflowStore';
CheckInWorkflowStore.handlers = {
    CHECK_IN_START: 'startCheckIn',
    CHECK_IN_BULK_UPLOAD_START: 'bulkUploadStart',
    CHECK_IN_BULK_UPLOAD_FINISH: 'bulkUploadFinish',
    CHECK_IN_BULK_UPLOAD_FAILED: 'bulkUploadFailed',
    SET_CHECK_INS: 'setCheckIns',
    CHECK_IN_REVIEW: 'reviewCheckIns',
    UPDATE_CHECK_IN_REVIEW: 'updateReviewCheckIns',
    RESET_CHECK_IN: 'resetCheckIns',
    CHECK_IN_CREATE_START: 'commitCheckStart',
    CHECK_IN_CREATE_FAILED: 'commitCheckInFailed',
    CHECK_IN_CREATE_FINISH: 'commitCheckInFinish',
    CHECK_IN_PREVIOUS: 'previous',
    CHECK_IN_DRAWER_OPEN: 'previousWithDrawerOpen',
    CLEAR_STORES: 'resetState',
    LOGOUT: 'resetState',
};

export default CheckInWorkflowStore;
