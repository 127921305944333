const ssn4Mask = [/\d/, /\d/, /\d/, /\d/];
const ssn9Mask = [
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    /\d/,
    /\d/,
];

export default function socialMask(value) {
    if (value.replace('-', '').length <= 4) {
        return ssn4Mask;
    }

    return ssn9Mask;
}
