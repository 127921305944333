import moment from 'moment';
import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import parse from 'date-fns/parse';
import formatDate from 'date-fns/format';
import startOfToday from 'date-fns/startOfToday';
import nameFormatter from '@audacious/web-common/formatters/nameFormatter';
import genderFormatter from '@audacious/web-common/formatters/genderFormatter';
import phoneFormatter from '@audacious/web-common/formatters/phoneFormatter';
import addressFormatter from '@audacious/web-common/formatters/addressFormatter';

function formatPatient(patient) {
    const {
        dob,
        phone,
        countryCode,
        gender,
        checkinTime,
        checkoutTime,
        checkoutType,
        checkoutLocation,
        dateOfDeath,
        address1,
        address2,
        city,
        state,
        zip,
        ssn,
    } = patient;

    let cityState = null;

    if (city && state) {
        cityState = `${city}, ${state}`;
    } else if (city) {
        cityState = city;
    } else if (state) {
        cityState = state;
    }

    const formattedCountryCode = !isNil(countryCode) ? countryCode : '+01';

    if (!isNil(zip) && zip.length > 0) {
        if (!isNil(cityState)) {
            cityState = `${cityState} ${zip}`;
        } else {
            cityState = zip;
        }
    }

    let maskedSSN = '';

    if (!isNil(ssn)) {
        maskedSSN = ssn.replace(/\d{3}-\d{2}/, '***-**');
    }

    return {
        name: nameFormatter('fml', patient),
        nameLastFirst: nameFormatter('lfm', patient),
        fullAddress: addressFormatter({
            streets: [address1, address2],
            city,
            state,
            postalCode: zip,
        }),
        address: {
            address1,
            address2,
            city,
            state,
            zipCode: zip,
        },
        cityState: !isNil(cityState) ? cityState : '-',
        dobString: isString(dob)
            ? moment(dob).format('MM/DD/YYYY')
            : formatDate(dob, 'MM/dd/yyyy'),
        dobDate: isString(dob) ? parse(dob, 'yyyy-MM-dd', startOfToday()) : dob,
        checkInDate:
            !isNil(checkinTime) && checkinTime.length > 0
                ? moment(checkinTime).format('MM/DD/YYYY')
                : null,
        checkOutDate:
            !isNil(checkoutTime) && checkoutTime.length > 0
                ? moment(checkoutTime).format('MM/DD/YYYY')
                : null,
        dateOfDeath:
            !isNil(dateOfDeath) && dateOfDeath.length > 0
                ? moment(dateOfDeath).format('MM/DD/YYYY')
                : null,
        checkOutType: checkoutType,
        checkOutLocation: checkoutLocation,
        phone: phoneFormatter({
            number: phone,
            countryCode: formattedCountryCode,
        }),
        gender: genderFormatter(gender),
        ssn: maskedSSN,
    };
}

export default formatPatient;
