import React from 'react';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import values from 'lodash/values';
import isNil from 'lodash/isNil';
import ButtonGroup from '@audacious/components/components/ButtonGroup';
import Button from '@audacious/components/components/Button';
import { Text } from '@audacious/components/components/Typography';
import { PageContainerGroup } from '@audacious/components/components/Page';
import { Row, Column } from '@audacious/components/components/Grid';
import OverlaySpinner from '@audacious/components/components/OverlaySpinner';
import getDateParts from '@audacious/web-common/utilities/getDateParts';
import {
    attemptCheckOut,
    cancelReview,
} from '../../../actions/check-out-actions';
import selectedPatientsPropType from './selected-patients-prop-type';
import ConfirmationTable from '../../common/confirmation-table';
import { clearStores } from '../../../actions/home-actions';

class CheckOutReview extends React.Component {
    constructor(props) {
        super(props);

        this.handleProceedClick = this.handleProceedClick.bind(this);
        this.handlePreviousClick = this.handlePreviousClick.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
    }

    handleProceedClick() {
        const {
            history,
            fluxibleContext: { executeAction },
            selectedPatients,
        } = this.props;

        const patients = values(selectedPatients).map(patient => {
            const patientConfirmation = {
                id: patient.id,
                ...patient.destination,
            };

            const { deceased } = patient.destination;

            if (!isNil(deceased)) {
                patientConfirmation.deceased = getDateParts(deceased);
            }

            return patientConfirmation;
        });

        executeAction(attemptCheckOut, {
            patients,
            history,
        });
    }

    handlePreviousClick() {
        const {
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(cancelReview);
    }

    handleCancelClick() {
        const {
            history,
            fluxibleContext: { executeAction },
        } = this.props;

        executeAction(clearStores);
        history.push('/home');
    }

    render() {
        const { selectedPatients, isCommitting, isErrored } = this.props;

        return (
            <>
                <PageContainerGroup>
                    <Row gutter="16" enableAfter>
                        <Column width="fill">
                            <Text size="xl" weight="bold">
                                Review Check-Out
                            </Text>
                        </Column>
                        {isErrored ? (
                            <Column width="content">
                                <Text size="md" weight="bold" color="danger">
                                    Failed to checkout patients
                                </Text>
                            </Column>
                        ) : null}
                    </Row>
                </PageContainerGroup>
                <ConfirmationTable
                    items={selectedPatients}
                    eventType="check-out"
                />
                <PageContainerGroup>
                    <Row gutter="16">
                        <Column width="fill">
                            <ButtonGroup>
                                <Button
                                    id="checkOutReviewProceed"
                                    color="secondary"
                                    variant="fill"
                                    onClick={this.handleProceedClick}
                                >
                                    Check-Out
                                </Button>
                                <Button
                                    id="check-out-review-prev"
                                    color="secondary"
                                    variant="outline"
                                    onClick={this.handlePreviousClick}
                                >
                                    Previous
                                </Button>
                            </ButtonGroup>
                        </Column>
                        <Column width="content">
                            <Button
                                id="check-out-review-cancel"
                                color="secondary"
                                variant="opaque"
                                onClick={this.handleCancelClick}
                            >
                                Cancel
                            </Button>
                        </Column>
                    </Row>
                </PageContainerGroup>
                {isCommitting ? (
                    <PageContainerGroup overlay>
                        <OverlaySpinner
                            size="md"
                            color="blue"
                            variant="solid"
                            alignment="centered"
                        />
                    </PageContainerGroup>
                ) : null}
            </>
        );
    }
}

CheckOutReview.propTypes = {
    selectedPatients: selectedPatientsPropType,
    history: PropTypes.shape({
        push: PropTypes.func,
    }).isRequired,
    isCommitting: PropTypes.bool.isRequired,
    isErrored: PropTypes.bool.isRequired,
    fluxibleContext: PropTypes.shape({
        executeAction: PropTypes.func,
    }).isRequired,
};

CheckOutReview.defaultProps = {
    selectedPatients: null,
};

export default withRouter(applyFluxibleContext(CheckOutReview));
