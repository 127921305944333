import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
// import { AuthenticatedPage } from '@audacious/client';
import CheckIn from './workflows/check-in';
import CheckOut from './workflows/check-out';
import MainPage from './workflows/main-page';
import Intermediate from './workflows/check-in/intermediate';
import CheckInError from './workflows/check-in/check-in-error';

function Root() {
    return (
        <Switch>
            <Route path="/home/checked-in">
                <MainPage listType="checked-in" />
            </Route>
            <Route path="/home/checked-out">
                <MainPage listType="checked-out" />
            </Route>
            <Route path="/check-in">
                <CheckIn />
            </Route>
            <Route path="/check-out">
                <CheckOut />
            </Route>
            <Route path="/intermediate">
                <Intermediate />
            </Route>
            <Route path="/error">
                <CheckInError />
            </Route>
            <Route>
                <Redirect to="/home/checked-in" />
            </Route>
        </Switch>
    );
}

Root.propTypes = {};

Root.defaultProps = {};

export default Root;
