import get from 'lodash/get';

function beforeRequest(actionContext) {
    actionContext.dispatch('CHECK_IN_CREATE_START');
}

function onSuccess(actionContext, { response }) {
    const { successes: succeeded, failures: failed } = response.data;

    actionContext.dispatch('MARK_NEW_CHECKED_IN_PATIENT', {
        succeeded,
        failed,
    });

    actionContext.dispatch('CHECK_IN_CREATE_FINISH');
}

function onFailed(actionContext, { data }) {
    actionContext.dispatch('CHECK_IN_CREATE_FAILED', {
        failed: data.patients,
        succeeded: [],
    });
}

export default config => ({
    serviceName: 'createCheckIns',
    authenticate: true,
    config: {
        method: 'POST',
        authenticate: true,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        url: get(config, 'endpoints.emergencyCensus.checkIn.create'),
    },
    beforeRequest,
    onSuccess,
    onFailed,
    onError: onFailed,
    onTimeout: onFailed,
    onFatal: onFailed,
});
